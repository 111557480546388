/* istanbul ignore file: this code is auto generated */
/* eslint-disable */
import { GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from 'react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Datetime: any;
  PositiveInt: any;
};

export type AddConnectionInput = {
  endpoint: Scalars['String'];
  name: Scalars['String'];
  token?: InputMaybe<Scalars['String']>;
  useBrowserAuth?: InputMaybe<Scalars['Boolean']>;
  useSSO?: InputMaybe<Scalars['Boolean']>;
  username?: InputMaybe<Scalars['String']>;
};

export type ApiToken = {
  __typename?: 'ApiToken';
  created: Scalars['Datetime'];
  expires: Scalars['Datetime'];
  id: Scalars['ID'];
  last_used?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  prefix: Scalars['String'];
};

/** ArchivedCloud, represents an archived Stardog Cloud instance */
export type ArchivedCloud = {
  __typename?: 'ArchivedCloud';
  bi_endpoint?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  customer_ref?: Maybe<Scalars['String']>;
  endpoint?: Maybe<Scalars['String']>;
  flavor?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  payment_ref?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

export type AzureProvider = {
  __typename?: 'AzureProvider';
  customerName: Scalars['String'];
};

export type BillingSession = {
  __typename?: 'BillingSession';
  session_id?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type BillingSessionInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type CancelCloudInput = {
  cloud_id: Scalars['String'];
  connection_id: Scalars['String'];
};

export type CheckoutLineItem = {
  flavor: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  quantity?: InputMaybe<Scalars['Int']>;
};

export type CloudCleanupInput = {
  cloudName?: InputMaybe<Scalars['String']>;
  userName?: InputMaybe<Scalars['String']>;
};

/** Stardog Cloud Flavor and Size info */
export type CloudFlavor = {
  __typename?: 'CloudFlavor';
  disk?: Maybe<Scalars['String']>;
  iops?: Maybe<Scalars['Float']>;
  is_cluster?: Maybe<Scalars['Boolean']>;
  memory?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nodes?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  vcpus?: Maybe<Scalars['Float']>;
};

/** Saved Connection info for a Stardog instance */
export type Connection = {
  __typename?: 'Connection';
  cloud?: Maybe<StardogCloud>;
  dashboard?: Maybe<Scalars['String']>;
  endpoint: Scalars['String'];
  id: Scalars['ID'];
  index: Scalars['Int'];
  isAllocating?: Maybe<Scalars['Boolean']>;
  isStardogCloud?: Maybe<Scalars['Boolean']>;
  isStardogFree?: Maybe<Scalars['Boolean']>;
  isWaitingForPayment?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  shouldShowDesigner?: Maybe<Scalars['Boolean']>;
  stripeSubscription?: Maybe<PurchaseSession>;
  stripeSubscriptionOrder?: Maybe<ProvisionedOrder>;
  token?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  useBrowserAuth?: Maybe<Scalars['Boolean']>;
  useSSO?: Maybe<Scalars['Boolean']>;
  user?: Maybe<User>;
  username?: Maybe<Scalars['String']>;
};

export type CreateApiTokenInput = {
  app_id?: InputMaybe<Scalars['ID']>;
  expires_days: Scalars['PositiveInt'];
  name: Scalars['String'];
};

export type CreateApiTokenResult = {
  __typename?: 'CreateApiTokenResult';
  secret: Scalars['String'];
};

export type CreateVoiceboxAppInput = {
  connection_id: Scalars['String'];
  database?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  named_graphs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  reasoning?: InputMaybe<Scalars['Boolean']>;
};

export type CustomerSsoSettings = {
  __typename?: 'CustomerSsoSettings';
  azureProviders: Array<Maybe<AzureProvider>>;
};

export type DeleteApiTokenInput = {
  app_id?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
};

/** Generic deletion response type to handle reporting success. */
export type DeletionResponse = {
  __typename?: 'DeletionResponse';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type EditApiTokenInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type EditConnectionInput = {
  endpoint?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  useBrowserAuth?: InputMaybe<Scalars['Boolean']>;
  useSSO?: InputMaybe<Scalars['Boolean']>;
  username?: InputMaybe<Scalars['String']>;
};

export type EditVoiceboxConversationInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

/** Example Configuration for jwt.yaml and stardog.properties */
export type ExampleConfig = {
  __typename?: 'ExampleConfig';
  id: Scalars['ID'];
  jwt_config: Scalars['String'];
  properties: Scalars['String'];
};

/** Generic response type to handle reporting success. */
export type GenericResponse = {
  __typename?: 'GenericResponse';
  error?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Grafana settings generate Grafana dashboard URLs */
export type GrafanaDashboardSettings = {
  __typename?: 'GrafanaDashboardSettings';
  id: Scalars['String'];
  slug: Scalars['String'];
};

/** Invitation to join a Stardog Cloud instance */
export type Invitation = {
  __typename?: 'Invitation';
  accepted?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Datetime']>;
  email?: Maybe<Scalars['String']>;
  endpoint?: Maybe<Scalars['String']>;
  existing_user?: Maybe<User>;
  expires?: Maybe<Scalars['Datetime']>;
  id?: Maybe<Scalars['ID']>;
  role?: Maybe<Scalars['String']>;
  sender?: Maybe<User>;
};

export type InvitationFlagInput = {
  id: Scalars['ID'];
};

export type InvitationInput = {
  email: Scalars['String'];
  endpoint: Scalars['String'];
  role: Scalars['String'];
};

export type ItemCount = {
  __typename?: 'ItemCount';
  count?: Maybe<Scalars['Int']>;
};

export type VoiceboxConversationsFilterInput = {
  connection_id?: InputMaybe<Scalars['String']>;
  include_designer?: InputMaybe<Scalars['Boolean']>;
};

export type MarketplaceSettings = {
  __typename?: 'MarketplaceSettings';
  marketplaceDatabase: Scalars['String'];
  marketplaceEndpoint: Scalars['String'];
  marketplacePassword: Scalars['String'];
  marketplaceUsername: Scalars['String'];
};

/** Root Mutation Type */
export type Mutation = {
  __typename?: 'Mutation';
  acceptInvitation?: Maybe<GenericResponse>;
  addConnection?: Maybe<Connection>;
  addInvitation?: Maybe<GenericResponse>;
  addShare?: Maybe<Share>;
  cancelCloud?: Maybe<GenericResponse>;
  checkoutCart?: Maybe<BillingSession>;
  createApiToken?: Maybe<CreateApiTokenResult>;
  createVoiceboxApp?: Maybe<GenericResponse>;
  deleteAccount?: Maybe<DeletionResponse>;
  deleteApiToken?: Maybe<GenericResponse>;
  deleteCloud?: Maybe<DeletionResponse>;
  deleteConnection?: Maybe<DeletionResponse>;
  deleteVoiceboxApp?: Maybe<GenericResponse>;
  deleteVoiceboxConversation?: Maybe<GenericResponse>;
  editApiToken?: Maybe<GenericResponse>;
  editConnection?: Maybe<Connection>;
  editVoiceboxConversation?: Maybe<GenericResponse>;
  generateConfiguration?: Maybe<ExampleConfig>;
  getStripeSessionUrl?: Maybe<BillingSession>;
  removePartnerConnection?: Maybe<GenericResponse>;
  resendEmail?: Maybe<GenericResponse>;
  trackEvent?: Maybe<GenericResponse>;
  updatePartnerConnection?: Maybe<GenericResponse>;
  updateProfile?: Maybe<User>;
  updateUserFeatures?: Maybe<User>;
  updateVoiceboxApp?: Maybe<GenericResponse>;
  upgradeCloud?: Maybe<BillingSession>;
  verifyInvitation?: Maybe<GenericResponse>;
};


/** Root Mutation Type */
export type MutationAcceptInvitationArgs = {
  input: InvitationFlagInput;
};


/** Root Mutation Type */
export type MutationAddConnectionArgs = {
  input: AddConnectionInput;
};


/** Root Mutation Type */
export type MutationAddInvitationArgs = {
  input: InvitationInput;
};


/** Root Mutation Type */
export type MutationAddShareArgs = {
  input: ShareInput;
};


/** Root Mutation Type */
export type MutationCancelCloudArgs = {
  input: CancelCloudInput;
};


/** Root Mutation Type */
export type MutationCheckoutCartArgs = {
  addOns?: InputMaybe<Array<InputMaybe<CheckoutLineItem>>>;
  item: CheckoutLineItem;
};


/** Root Mutation Type */
export type MutationCreateApiTokenArgs = {
  input: CreateApiTokenInput;
};


/** Root Mutation Type */
export type MutationCreateVoiceboxAppArgs = {
  input: CreateVoiceboxAppInput;
};


/** Root Mutation Type */
export type MutationDeleteApiTokenArgs = {
  input: DeleteApiTokenInput;
};


/** Root Mutation Type */
export type MutationDeleteCloudArgs = {
  input: CloudCleanupInput;
};


/** Root Mutation Type */
export type MutationDeleteConnectionArgs = {
  name: Scalars['String'];
};


/** Root Mutation Type */
export type MutationDeleteVoiceboxAppArgs = {
  id: Scalars['ID'];
};


/** Root Mutation Type */
export type MutationDeleteVoiceboxConversationArgs = {
  conversation_id: Scalars['ID'];
};


/** Root Mutation Type */
export type MutationEditApiTokenArgs = {
  input: EditApiTokenInput;
};


/** Root Mutation Type */
export type MutationEditConnectionArgs = {
  input: EditConnectionInput;
};


/** Root Mutation Type */
export type MutationEditVoiceboxConversationArgs = {
  input: EditVoiceboxConversationInput;
};


/** Root Mutation Type */
export type MutationGenerateConfigurationArgs = {
  endpoint: Scalars['String'];
};


/** Root Mutation Type */
export type MutationRemovePartnerConnectionArgs = {
  input: RemovePartnerConnectionInput;
};


/** Root Mutation Type */
export type MutationTrackEventArgs = {
  input: TrackEventInput;
};


/** Root Mutation Type */
export type MutationUpdatePartnerConnectionArgs = {
  input: UpdatePartnerConnectionInput;
};


/** Root Mutation Type */
export type MutationUpdateProfileArgs = {
  input?: InputMaybe<ProfileInput>;
};


/** Root Mutation Type */
export type MutationUpdateUserFeaturesArgs = {
  input: UserFeaturesInput;
  user_id: Scalars['ID'];
};


/** Root Mutation Type */
export type MutationUpdateVoiceboxAppArgs = {
  input: UpdateVoiceboxAppInput;
};


/** Root Mutation Type */
export type MutationUpgradeCloudArgs = {
  connectionId: Scalars['ID'];
  item: CheckoutLineItem;
};


/** Root Mutation Type */
export type MutationVerifyInvitationArgs = {
  input: InvitationFlagInput;
};

/**
 * An Auth0 auth token stored for the user, do not add secret values here such as
 * refresh_token or client_id.
 */
export type OAuthToken = {
  __typename?: 'OAuthToken';
  access_token?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  id_token?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  user: User;
};

/** To page through response. */
export type PagingInput = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** Databricks Partner Information */
export type PartnerConnectionDetail = {
  __typename?: 'PartnerConnectionDetail';
  cluster_id?: Maybe<Scalars['String']>;
  connection_id?: Maybe<Scalars['String']>;
  databricks_connection_name?: Maybe<Scalars['String']>;
  databricks_personal_token_id?: Maybe<Scalars['String']>;
  databricks_workspace_id?: Maybe<Scalars['String']>;
  http_path?: Maybe<Scalars['String']>;
  is_configured_resources?: Maybe<Scalars['Boolean']>;
  jdbc_url?: Maybe<Scalars['String']>;
  stardog_server_connection?: Maybe<Connection>;
  user_email?: Maybe<Scalars['String']>;
  user_first_name?: Maybe<Scalars['String']>;
  workspace_url?: Maybe<Scalars['String']>;
};

export type ProfileInput = {
  best_describes_company: Scalars['String'];
  best_describes_role: Scalars['String'];
  company: Scalars['String'];
  familiarity_with_kgs: Scalars['String'];
  first_name: Scalars['String'];
  industry: Scalars['String'];
  last_name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  use_case: Scalars['String'];
};

export type ProvisionedOrder = {
  __typename?: 'ProvisionedOrder';
  payment_system?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  trial_days_remaining?: Maybe<Scalars['Int']>;
  trial_days_total?: Maybe<Scalars['Int']>;
};

export type PurchaseSession = {
  __typename?: 'PurchaseSession';
  isExpired?: Maybe<Scalars['Boolean']>;
  isWaitingForPayment?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** Root Query Type */
export type Query = {
  __typename?: 'Query';
  apiTokenCount?: Maybe<ItemCount>;
  checkCloudQueue?: Maybe<QueueCounts>;
  customerSsoSettings?: Maybe<CustomerSsoSettings>;
  generateToken?: Maybe<OAuthToken>;
  getConnection?: Maybe<Connection>;
  getConnectionByIndex?: Maybe<Connection>;
  getInvitation?: Maybe<Invitation>;
  getShareByShortHash?: Maybe<Share>;
  getStardogCloud?: Maybe<StardogCloud>;
  getStripePrices?: Maybe<Array<Maybe<StripePrice>>>;
  getStripeSubscriptionOrder?: Maybe<ProvisionedOrder>;
  getUser?: Maybe<User>;
  getUserArchivedClouds?: Maybe<Array<Maybe<ArchivedCloud>>>;
  getUserClouds?: Maybe<Array<Maybe<StardogCloud>>>;
  getUserConnections?: Maybe<Array<Maybe<Connection>>>;
  getUserCurrentPartnerConnection?: Maybe<PartnerConnectionDetail>;
  getUserSearchDetails?: Maybe<UserSearchDetails>;
  /** Retrieve a single Voicebox conversation for the authenticated user by the conversation id. */
  getVoiceboxConversation?: Maybe<VoiceboxConversation>;
  grafanaHighLevelDashboardSettings?: Maybe<GrafanaDashboardSettings>;
  listApiTokens?: Maybe<Array<Maybe<ApiToken>>>;
  listConnections?: Maybe<Array<Maybe<Connection>>>;
  listConnectionsByEndpoint?: Maybe<Array<Maybe<Connection>>>;
  listInactiveClouds?: Maybe<Array<Maybe<StardogCloud>>>;
  listStardogCloud?: Maybe<Array<Maybe<StardogCloud>>>;
  /** Retrieve Voicebox Applications owned by the authenticated user. */
  listVoiceboxApps?: Maybe<Array<Maybe<VoiceboxApp>>>;
  /**
   * Retrieve Voicebox conversations for the authenticated user, ordered by creation date, with the newest first.
   * Use PagingInput to paginate. If PagingInput is omitted, all conversations are returned.
   * Designer conversations will only be returned if include_designer is set to true.
   * Use connection_id to filter by connection_id, if no connection_id is provided, all conversations are returned.
   */
  listVoiceboxConversations?: Maybe<Array<Maybe<VoiceboxConversation>>>;
  marketplaceSettings?: Maybe<MarketplaceSettings>;
  profile?: Maybe<User>;
  searchUsers?: Maybe<Array<Maybe<User>>>;
  settings: Settings;
  userPartnerConnections?: Maybe<Array<Maybe<PartnerConnectionDetail>>>;
  /**
   * Get the amount of total Voicebox conversation's an authenticated user. May be helpful in conjunction with listVoiceboxConversations
   * to page through all conversations.
   */
  voiceboxConversationCount?: Maybe<ItemCount>;
};


/** Root Query Type */
export type QueryApiTokenCountArgs = {
  app_id?: InputMaybe<Scalars['ID']>;
};


/** Root Query Type */
export type QueryGenerateTokenArgs = {
  endpoint: Scalars['String'];
};


/** Root Query Type */
export type QueryGetConnectionArgs = {
  name: Scalars['String'];
};


/** Root Query Type */
export type QueryGetConnectionByIndexArgs = {
  index: Scalars['Int'];
};


/** Root Query Type */
export type QueryGetInvitationArgs = {
  id: Scalars['ID'];
};


/** Root Query Type */
export type QueryGetShareByShortHashArgs = {
  shortHash: Scalars['String'];
};


/** Root Query Type */
export type QueryGetStardogCloudArgs = {
  pk: Scalars['String'];
};


/** Root Query Type */
export type QueryGetStripeSubscriptionOrderArgs = {
  cloudId: Scalars['ID'];
};


/** Root Query Type */
export type QueryGetUserArgs = {
  user_id: Scalars['String'];
};


/** Root Query Type */
export type QueryGetUserArchivedCloudsArgs = {
  user_id: Scalars['String'];
};


/** Root Query Type */
export type QueryGetUserCloudsArgs = {
  user_id: Scalars['String'];
};


/** Root Query Type */
export type QueryGetUserConnectionsArgs = {
  user_id: Scalars['String'];
};


/** Root Query Type */
export type QueryGetUserSearchDetailsArgs = {
  filters?: InputMaybe<UserSearchFiltersInput>;
  token: Scalars['String'];
};


/** Root Query Type */
export type QueryGetVoiceboxConversationArgs = {
  conversation_id: Scalars['String'];
};


/** Root Query Type */
export type QueryListApiTokensArgs = {
  app_id?: InputMaybe<Scalars['ID']>;
  paging?: InputMaybe<PagingInput>;
};


/** Root Query Type */
export type QueryListConnectionsByEndpointArgs = {
  endpoint: Scalars['String'];
};


/** Root Query Type */
export type QueryListInactiveCloudsArgs = {
  flavor?: InputMaybe<Scalars['String']>;
};


/** Root Query Type */
export type QueryListStardogCloudArgs = {
  inactive_days?: InputMaybe<Scalars['Int']>;
};


/** Root Query Type */
export type QueryListVoiceboxConversationsArgs = {
  filterInput?: InputMaybe<VoiceboxConversationsFilterInput>;
  paging?: InputMaybe<PagingInput>;
};


/** Root Query Type */
export type QuerySearchUsersArgs = {
  filters?: InputMaybe<UserSearchFiltersInput>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  token: Scalars['String'];
};


/** Root Query Type */
export type QueryVoiceboxConversationCountArgs = {
  filterInput?: InputMaybe<VoiceboxConversationsFilterInput>;
};

/** Contains the counts of available cloud resources to sell. */
export type QueueCounts = {
  __typename?: 'QueueCounts';
  medium_count?: Maybe<Scalars['Float']>;
  micro_count?: Maybe<Scalars['Float']>;
  small_count?: Maybe<Scalars['Float']>;
};

/** Quota limits and usage for a given user. */
export type Quota = {
  __typename?: 'Quota';
  available?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type RemovePartnerConnectionInput = {
  connection_id: Scalars['String'];
};

/** Settings, these are settings that control the front end display */
export type Settings = {
  __typename?: 'Settings';
  auth0Auth: Scalars['Boolean'];
  azureAuth: Scalars['Boolean'];
  baseURL: Scalars['String'];
  dataMarketplace: Scalars['Boolean'];
  designerVersion: Scalars['String'];
  explorerVersion: Scalars['String'];
  friendlyName: Scalars['String'];
  geoaxisAuth: Scalars['Boolean'];
  googleAuth: Scalars['Boolean'];
  homeFooterLinks: Scalars['Boolean'];
  keycloakAuth: Scalars['Boolean'];
  openidAuth: Scalars['Boolean'];
  passwordAuth: Scalars['Boolean'];
  portal: Scalars['Boolean'];
  stardogEndpoint: Scalars['String'];
  studioVersion: Scalars['String'];
};

/** Share URL, short urls for sharing */
export type Share = {
  __typename?: 'Share';
  endpoint?: Maybe<Scalars['String']>;
  expiration?: Maybe<Scalars['Datetime']>;
  last_accessed?: Maybe<Scalars['Datetime']>;
  lookup_count?: Maybe<Scalars['Int']>;
  service?: Maybe<Scalars['String']>;
  short_url?: Maybe<Scalars['String']>;
  target_path?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Datetime']>;
};

export type ShareInput = {
  endpoint: Scalars['String'];
  expires: Scalars['Int'];
  service: Scalars['String'];
  target_path: Scalars['String'];
};

/** Stardog Cloud, represents an instance of Cloud that is owned by the user */
export type StardogCloud = {
  __typename?: 'StardogCloud';
  bi_endpoint?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  endpoint?: Maybe<Scalars['String']>;
  flavor?: Maybe<CloudFlavor>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  owner?: Maybe<User>;
  payment_ref?: Maybe<Scalars['String']>;
  price_ref?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['String']>;
};

/** Stripe Customer: If the user is a paying customer */
export type StripeCustomer = {
  __typename?: 'StripeCustomer';
  cloud_quota?: Maybe<Quota>;
  customer_id?: Maybe<Scalars['String']>;
  is_cloud_quota_breached?: Maybe<Scalars['Boolean']>;
};

export type StripePrice = {
  __typename?: 'StripePrice';
  amount?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  product_info?: Maybe<StripeProduct>;
  stardog_billing_type?: Maybe<Scalars['String']>;
  stardog_flavor?: Maybe<Scalars['String']>;
};

export type StripeProduct = {
  __typename?: 'StripeProduct';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  metadata?: Maybe<StripeProductMetadata>;
  name?: Maybe<Scalars['String']>;
};

export type StripeProductMetadata = {
  __typename?: 'StripeProductMetadata';
  flavor?: Maybe<Scalars['String']>;
};

export type SystemVoiceboxMessageContext = {
  __typename?: 'SystemVoiceboxMessageContext';
  actions?: Maybe<Array<Maybe<VoicboxSystemMessageAction>>>;
  followup_examples?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['ID'];
};

export type TrackEventInput = {
  client_type?: InputMaybe<Scalars['String']>;
  event: Scalars['String'];
  properties?: InputMaybe<Scalars['String']>;
};

export type UpdatePartnerConnectionInput = {
  connection_id: Scalars['String'];
  databricks_connection_name: Scalars['String'];
  stardog_connection_id: Scalars['String'];
};

export type UpdateVoiceboxAppInput = {
  database?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  model?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  named_graphs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  reasoning?: InputMaybe<Scalars['Boolean']>;
};

/**
 * The user model, this represents a user from Auth0. Only fields defined here
 * are exposed in the possible GraphQL responses.
 */
export type User = {
  __typename?: 'User';
  best_describes_company?: Maybe<Scalars['String']>;
  best_describes_role?: Maybe<Scalars['String']>;
  can_provision_cloud?: Maybe<Scalars['Boolean']>;
  company?: Maybe<Scalars['String']>;
  date_joined?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  familiarity_with_kgs?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  has_stardog_free?: Maybe<Scalars['Boolean']>;
  has_updated_profile?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  industry?: Maybe<Scalars['String']>;
  is_authenticated: Scalars['Boolean'];
  is_databricks_user?: Maybe<Scalars['Boolean']>;
  is_ephemeral?: Maybe<Scalars['Boolean']>;
  is_partner_user?: Maybe<Scalars['Boolean']>;
  is_staff?: Maybe<Scalars['Boolean']>;
  is_static_voicebox?: Maybe<Scalars['Boolean']>;
  /** @deprecated is_studio_voicebox_enabled is deprecated. Use is_voicebox_enabled instead. */
  is_studio_voicebox_enabled?: Maybe<Scalars['Boolean']>;
  is_superuser?: Maybe<Scalars['Boolean']>;
  is_verified?: Maybe<Scalars['Boolean']>;
  is_voicebox_enabled?: Maybe<Scalars['Boolean']>;
  last_login?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  needs_stardog_free?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['String']>;
  stripe_customer?: Maybe<StripeCustomer>;
  sub?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  use_case?: Maybe<Scalars['String']>;
  userflow_signature?: Maybe<Scalars['String']>;
  username: Scalars['String'];
};

export type UserFeaturesInput = {
  is_static_voicebox?: InputMaybe<Scalars['Boolean']>;
  /** @deprecated is_studio_voicebox_enabled is deprecated. Use is_voicebox_enabled instead. */
  is_studio_voicebox_enabled?: InputMaybe<Scalars['Boolean']>;
  is_voicebox_enabled?: InputMaybe<Scalars['Boolean']>;
};

export type UserSearchDetails = {
  __typename?: 'UserSearchDetails';
  total?: Maybe<Scalars['Int']>;
};

export type UserSearchFiltersInput = {
  is_staff?: InputMaybe<Scalars['Boolean']>;
  /** @deprecated is_studio_voicebox_enabled is deprecated. Use is_voicebox_enabled instead. */
  is_studio_voicebox_enabled?: InputMaybe<Scalars['Boolean']>;
  is_voicebox_enabled?: InputMaybe<Scalars['Boolean']>;
};

export type UserVoiceboxMessageContext = {
  __typename?: 'UserVoiceboxMessageContext';
  app?: Maybe<Scalars['String']>;
  connection_id?: Maybe<Scalars['String']>;
  database?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  model?: Maybe<Scalars['String']>;
  named_graphs?: Maybe<Array<Maybe<Scalars['String']>>>;
  reasoning?: Maybe<Scalars['Boolean']>;
};

export type VoicboxSystemMessageAction = {
  __typename?: 'VoicboxSystemMessageAction';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type VoiceboxApp = {
  __typename?: 'VoiceboxApp';
  connection_id: Scalars['String'];
  database?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  model?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  named_graphs?: Maybe<Array<Maybe<Scalars['String']>>>;
  reasoning?: Maybe<Scalars['Boolean']>;
};

/** A user's conversation with Voicebox */
export type VoiceboxConversation = {
  __typename?: 'VoiceboxConversation';
  created?: Maybe<Scalars['Datetime']>;
  /** The first message in the conversation that was sent by the user. */
  first_user_message?: Maybe<VoiceboxMessage>;
  id: Scalars['ID'];
  /** The last message in the conversation that was sent by the user. */
  last_user_message?: Maybe<VoiceboxMessage>;
  /** Message history ordered oldest to newest. */
  message_history?: Maybe<Array<Maybe<VoiceboxMessage>>>;
  name?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['Datetime']>;
};

/** A Message within a Voicebox conversation. */
export type VoiceboxMessage = {
  __typename?: 'VoiceboxMessage';
  comment?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['Datetime']>;
  id: Scalars['ID'];
  score?: Maybe<Scalars['Float']>;
  sender?: Maybe<Scalars['String']>;
  system_message_context?: Maybe<SystemVoiceboxMessageContext>;
  user_message_context?: Maybe<UserVoiceboxMessageContext>;
};

export type AcceptInvitationMutationVariables = Exact<{
  input: InvitationFlagInput;
}>;


export type AcceptInvitationMutation = { __typename?: 'Mutation', acceptInvitation?: { __typename?: 'GenericResponse', success: boolean, error?: string | null } | null };

export type AddConnectionMutationVariables = Exact<{
  input: AddConnectionInput;
}>;


export type AddConnectionMutation = { __typename?: 'Mutation', addConnection?: { __typename?: 'Connection', id: string, index: number, name: string, endpoint: string, username?: string | null, token?: string | null, useBrowserAuth?: boolean | null, useSSO?: boolean | null } | null };

export type AddInvitationMutationVariables = Exact<{
  input: InvitationInput;
}>;


export type AddInvitationMutation = { __typename?: 'Mutation', addInvitation?: { __typename?: 'GenericResponse', success: boolean, error?: string | null } | null };

export type AddShareMutationVariables = Exact<{
  input: ShareInput;
}>;


export type AddShareMutation = { __typename?: 'Mutation', addShare?: { __typename?: 'Share', short_url?: string | null, target_path?: string | null, endpoint?: string | null, service?: string | null, expiration?: any | null } | null };

export type CancelCloudMutationVariables = Exact<{
  input: CancelCloudInput;
}>;


export type CancelCloudMutation = { __typename?: 'Mutation', cancelCloud?: { __typename?: 'GenericResponse', success: boolean, error?: string | null } | null };

export type CheckCloudQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckCloudQuery = { __typename?: 'Query', checkCloudQueue?: { __typename?: 'QueueCounts', small_count?: number | null } | null, listConnections?: Array<{ __typename?: 'Connection', name: string } | null> | null, profile?: { __typename?: 'User', stripe_customer?: { __typename?: 'StripeCustomer', is_cloud_quota_breached?: boolean | null, cloud_quota?: { __typename?: 'Quota', total?: number | null, available?: number | null } | null } | null } | null };

export type CheckoutCartMutationVariables = Exact<{
  item: CheckoutLineItem;
  addOns?: InputMaybe<Array<InputMaybe<CheckoutLineItem>> | InputMaybe<CheckoutLineItem>>;
}>;


export type CheckoutCartMutation = { __typename?: 'Mutation', checkoutCart?: { __typename?: 'BillingSession', url?: string | null } | null };

export type CreateApiTokenMutationVariables = Exact<{
  input: CreateApiTokenInput;
}>;


export type CreateApiTokenMutation = { __typename?: 'Mutation', createApiToken?: { __typename?: 'CreateApiTokenResult', secret: string } | null };

export type CreateVoiceboxAppMutationVariables = Exact<{
  input: CreateVoiceboxAppInput;
}>;


export type CreateVoiceboxAppMutation = { __typename?: 'Mutation', createVoiceboxApp?: { __typename?: 'GenericResponse', success: boolean, error?: string | null } | null };

export type DeleteAccountMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteAccountMutation = { __typename?: 'Mutation', deleteAccount?: { __typename?: 'DeletionResponse', success: boolean, error?: string | null } | null };

export type DeleteApiTokenMutationVariables = Exact<{
  input: DeleteApiTokenInput;
}>;


export type DeleteApiTokenMutation = { __typename?: 'Mutation', deleteApiToken?: { __typename?: 'GenericResponse', success: boolean, error?: string | null } | null };

export type DeleteCloudMutationVariables = Exact<{
  input: CloudCleanupInput;
}>;


export type DeleteCloudMutation = { __typename?: 'Mutation', deleteCloud?: { __typename?: 'DeletionResponse', success: boolean, error?: string | null } | null };

export type DeleteConnectionMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type DeleteConnectionMutation = { __typename?: 'Mutation', deleteConnection?: { __typename?: 'DeletionResponse', success: boolean, error?: string | null } | null };

export type DeleteVoiceboxAppMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteVoiceboxAppMutation = { __typename?: 'Mutation', deleteVoiceboxApp?: { __typename?: 'GenericResponse', success: boolean, error?: string | null } | null };

export type DeleteVoiceboxConversationMutationVariables = Exact<{
  conversation_id: Scalars['ID'];
}>;


export type DeleteVoiceboxConversationMutation = { __typename?: 'Mutation', deleteVoiceboxConversation?: { __typename?: 'GenericResponse', success: boolean, error?: string | null } | null };

export type EditConnectionMutationVariables = Exact<{
  input: EditConnectionInput;
}>;


export type EditConnectionMutation = { __typename?: 'Mutation', editConnection?: { __typename?: 'Connection', id: string, index: number, name: string, endpoint: string, username?: string | null, token?: string | null, useBrowserAuth?: boolean | null, useSSO?: boolean | null } | null };

export type EditVoiceboxConversationMutationVariables = Exact<{
  input: EditVoiceboxConversationInput;
}>;


export type EditVoiceboxConversationMutation = { __typename?: 'Mutation', editVoiceboxConversation?: { __typename?: 'GenericResponse', success: boolean, error?: string | null } | null };

export type GenerateConfigurationMutationVariables = Exact<{
  endpoint: Scalars['String'];
}>;


export type GenerateConfigurationMutation = { __typename?: 'Mutation', generateConfiguration?: { __typename?: 'ExampleConfig', id: string, jwt_config: string, properties: string } | null };

export type GenerateTokenQueryVariables = Exact<{
  endpoint: Scalars['String'];
}>;


export type GenerateTokenQuery = { __typename?: 'Query', generateToken?: { __typename?: 'OAuthToken', access_token?: string | null, user: { __typename?: 'User', username: string } } | null };

export type GetConnectionByIndexQueryVariables = Exact<{
  index: Scalars['Int'];
}>;


export type GetConnectionByIndexQuery = { __typename?: 'Query', connection?: { __typename?: 'Connection', id: string, index: number, name: string, endpoint: string, username?: string | null, token?: string | null, useBrowserAuth?: boolean | null, useSSO?: boolean | null, isStardogFree?: boolean | null, isStardogCloud?: boolean | null, isAllocating?: boolean | null, shouldShowDesigner?: boolean | null, cloud?: { __typename?: 'StardogCloud', id?: string | null, flavor?: { __typename?: 'CloudFlavor', name?: string | null } | null, owner?: { __typename?: 'User', id?: string | null, email?: string | null, is_authenticated: boolean, username: string } | null } | null, stripeSubscription?: { __typename?: 'PurchaseSession', url?: string | null, status?: string | null, isWaitingForPayment?: boolean | null } | null, stripeSubscriptionOrder?: { __typename?: 'ProvisionedOrder', payment_system?: string | null, trial_days_remaining?: number | null, trial_days_total?: number | null } | null } | null };

export type GetCustomerSsoSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCustomerSsoSettingsQuery = { __typename?: 'Query', customerSsoSettings?: { __typename?: 'CustomerSsoSettings', azureProviders: Array<{ __typename?: 'AzureProvider', customerName: string } | null> } | null };

export type GetInvitationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetInvitationQuery = { __typename?: 'Query', getInvitation?: { __typename?: 'Invitation', id?: string | null, email?: string | null, endpoint?: string | null, role?: string | null, created?: any | null, expires?: any | null, accepted?: boolean | null, sender?: { __typename?: 'User', email?: string | null, first_name?: string | null, last_name?: string | null } | null, existing_user?: { __typename?: 'User', email?: string | null, first_name?: string | null, last_name?: string | null } | null } | null };

export type MarketplaceSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type MarketplaceSettingsQuery = { __typename?: 'Query', marketplaceSettings?: { __typename?: 'MarketplaceSettings', marketplaceUsername: string, marketplacePassword: string, marketplaceEndpoint: string, marketplaceDatabase: string } | null };

export type GetProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProductsQuery = { __typename?: 'Query', getStripePrices?: Array<{ __typename?: 'StripePrice', id?: string | null, nickname?: string | null, amount?: string | null, stardog_billing_type?: string | null, product_info?: { __typename?: 'StripeProduct', name?: string | null, description?: string | null, metadata?: { __typename?: 'StripeProductMetadata', flavor?: string | null } | null } | null } | null> | null, checkCloudQueue?: { __typename?: 'QueueCounts', small_count?: number | null, medium_count?: number | null, micro_count?: number | null } | null, profile?: { __typename?: 'User', has_stardog_free?: boolean | null, is_staff?: boolean | null, stripe_customer?: { __typename?: 'StripeCustomer', is_cloud_quota_breached?: boolean | null, cloud_quota?: { __typename?: 'Quota', total?: number | null, available?: number | null } | null } | null } | null };

export type GetReportQueryVariables = Exact<{
  inactiveDays?: InputMaybe<Scalars['Int']>;
}>;


export type GetReportQuery = { __typename?: 'Query', listStardogCloud?: Array<{ __typename?: 'StardogCloud', id?: string | null, name?: string | null, status?: string | null, endpoint?: string | null, flavor?: { __typename?: 'CloudFlavor', name?: string | null } | null, owner?: { __typename?: 'User', username: string, last_login?: string | null } | null } | null> | null, checkCloudQueue?: { __typename?: 'QueueCounts', small_count?: number | null, medium_count?: number | null, micro_count?: number | null } | null };

export type GetShareByShortHashQueryVariables = Exact<{
  shortHash: Scalars['String'];
}>;


export type GetShareByShortHashQuery = { __typename?: 'Query', getShareByShortHash?: { __typename?: 'Share', target_path?: string | null, endpoint?: string | null, service?: string | null, last_accessed?: any | null } | null };

export type GetStripeSessionUrlMutationVariables = Exact<{ [key: string]: never; }>;


export type GetStripeSessionUrlMutation = { __typename?: 'Mutation', getStripeSessionUrl?: { __typename?: 'BillingSession', url?: string | null } | null };

export type GetUserCurrentPartnerConnectionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserCurrentPartnerConnectionQuery = { __typename?: 'Query', profile?: { __typename?: 'User', first_name?: string | null, last_name?: string | null, email?: string | null, username: string } | null, getUserCurrentPartnerConnection?: { __typename?: 'PartnerConnectionDetail', databricks_workspace_id?: string | null, jdbc_url?: string | null, workspace_url?: string | null, user_email?: string | null, connection_id?: string | null, user_first_name?: string | null, is_configured_resources?: boolean | null, databricks_connection_name?: string | null, databricks_personal_token_id?: string | null, cluster_id?: string | null, stardog_server_connection?: { __typename?: 'Connection', name: string, id: string, isStardogCloud?: boolean | null, index: number, endpoint: string } | null } | null };

export type GetUserInfoQueryVariables = Exact<{
  user_id: Scalars['String'];
}>;


export type GetUserInfoQuery = { __typename?: 'Query', getUser?: { __typename?: 'User', company?: string | null, email?: string | null, first_name?: string | null, has_stardog_free?: boolean | null, id?: string | null, is_databricks_user?: boolean | null, is_staff?: boolean | null, is_voicebox_enabled?: boolean | null, is_static_voicebox?: boolean | null, is_verified?: boolean | null, is_authenticated: boolean, last_login?: string | null, last_name?: string | null, phone?: string | null, title?: string | null, use_case?: string | null, date_joined?: string | null } | null, getUserConnections?: Array<{ __typename?: 'Connection', id: string, name: string, endpoint: string, useSSO?: boolean | null, type?: string | null } | null> | null, getUserClouds?: Array<{ __typename?: 'StardogCloud', created?: string | null, endpoint?: string | null, id?: string | null, name?: string | null, status?: string | null, region?: string | null, updated?: string | null, payment_ref?: string | null, flavor?: { __typename?: 'CloudFlavor', name?: string | null } | null } | null> | null, getUserArchivedClouds?: Array<{ __typename?: 'ArchivedCloud', created?: string | null, endpoint?: string | null, flavor?: string | null, id?: string | null, name?: string | null, payment_ref?: string | null, region?: string | null } | null> | null, grafanaHighLevelDashboardSettings?: { __typename?: 'GrafanaDashboardSettings', id: string, slug: string } | null };

export type GetVoiceboxConversationQueryVariables = Exact<{
  conversation_id: Scalars['String'];
}>;


export type GetVoiceboxConversationQuery = { __typename?: 'Query', getVoiceboxConversation?: { __typename?: 'VoiceboxConversation', id: string, message_history?: Array<{ __typename?: 'VoiceboxMessage', id: string, content?: string | null, sender?: string | null, created?: any | null } | null> | null } | null };

export type ListApiTokensQueryVariables = Exact<{
  app_id?: InputMaybe<Scalars['ID']>;
  paging?: InputMaybe<PagingInput>;
}>;


export type ListApiTokensQuery = { __typename?: 'Query', listApiTokens?: Array<{ __typename?: 'ApiToken', created: any, expires: any, id: string, last_used?: any | null, name?: string | null } | null> | null, apiTokenCount?: { __typename?: 'ItemCount', count?: number | null } | null };

export type ListConnectionsByEndpointQueryVariables = Exact<{
  endpoint: Scalars['String'];
}>;


export type ListConnectionsByEndpointQuery = { __typename?: 'Query', listConnectionsByEndpoint?: Array<{ __typename?: 'Connection', id: string, index: number, name: string, endpoint: string, username?: string | null, token?: string | null, useBrowserAuth?: boolean | null, useSSO?: boolean | null, isStardogFree?: boolean | null, isStardogCloud?: boolean | null, isAllocating?: boolean | null, cloud?: { __typename?: 'StardogCloud', id?: string | null } | null, stripeSubscription?: { __typename?: 'PurchaseSession', url?: string | null, status?: string | null, isWaitingForPayment?: boolean | null, isExpired?: boolean | null } | null } | null> | null };

export type ListVoiceboxAppsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListVoiceboxAppsQuery = { __typename?: 'Query', listVoiceboxApps?: Array<{ __typename?: 'VoiceboxApp', id: string, name: string, connection_id: string, database?: string | null, model?: string | null, named_graphs?: Array<string | null> | null, reasoning?: boolean | null } | null> | null };

export type ListVoiceboxConversationsQueryVariables = Exact<{
  paging?: InputMaybe<PagingInput>;
  filterInput?: InputMaybe<VoiceboxConversationsFilterInput>;
}>;


export type ListVoiceboxConversationsQuery = { __typename?: 'Query', listVoiceboxConversations?: Array<{ __typename?: 'VoiceboxConversation', id: string, name?: string | null, created?: any | null, first_user_message?: { __typename?: 'VoiceboxMessage', content?: string | null, user_message_context?: { __typename?: 'UserVoiceboxMessageContext', app?: string | null } | null } | null, last_user_message?: { __typename?: 'VoiceboxMessage', content?: string | null, created?: any | null, user_message_context?: { __typename?: 'UserVoiceboxMessageContext', connection_id?: string | null } | null } | null } | null> | null, voiceboxConversationCount?: { __typename?: 'ItemCount', count?: number | null } | null };

export type ProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfileQuery = { __typename?: 'Query', profile?: { __typename?: 'User', id?: string | null, is_superuser?: boolean | null, is_staff?: boolean | null, has_updated_profile?: boolean | null, is_ephemeral?: boolean | null, is_authenticated: boolean, is_verified?: boolean | null, is_voicebox_enabled?: boolean | null, is_static_voicebox?: boolean | null, username: string, first_name?: string | null, last_name?: string | null, email?: string | null, company?: string | null, title?: string | null, phone?: string | null, use_case?: string | null, is_databricks_user?: boolean | null, userflow_signature?: string | null, date_joined?: string | null, familiarity_with_kgs?: string | null, best_describes_role?: string | null, industry?: string | null, best_describes_company?: string | null, stripe_customer?: { __typename?: 'StripeCustomer', customer_id?: string | null } | null } | null };

export type RemovePartnerConnectionMutationVariables = Exact<{
  input: RemovePartnerConnectionInput;
}>;


export type RemovePartnerConnectionMutation = { __typename?: 'Mutation', removePartnerConnection?: { __typename?: 'GenericResponse', success: boolean, error?: string | null } | null };

export type ResendEmailMutationVariables = Exact<{ [key: string]: never; }>;


export type ResendEmailMutation = { __typename?: 'Mutation', resendEmail?: { __typename?: 'GenericResponse', success: boolean, error?: string | null } | null };

export type SavedConnectionsQueryVariables = Exact<{ [key: string]: never; }>;


export type SavedConnectionsQuery = { __typename?: 'Query', listConnections?: Array<{ __typename?: 'Connection', id: string, index: number, name: string, endpoint: string, username?: string | null, token?: string | null, useBrowserAuth?: boolean | null, useSSO?: boolean | null, isStardogFree?: boolean | null, isStardogCloud?: boolean | null, isAllocating?: boolean | null, cloud?: { __typename?: 'StardogCloud', id?: string | null, owner?: { __typename?: 'User', email?: string | null, username: string, is_authenticated: boolean } | null, flavor?: { __typename?: 'CloudFlavor', name?: string | null } | null } | null, stripeSubscription?: { __typename?: 'PurchaseSession', url?: string | null, status?: string | null, isWaitingForPayment?: boolean | null, isExpired?: boolean | null } | null } | null> | null };

export type GetUserSearchResultsQueryVariables = Exact<{
  token: Scalars['String'];
  filters?: InputMaybe<UserSearchFiltersInput>;
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type GetUserSearchResultsQuery = { __typename?: 'Query', searchUsers?: Array<{ __typename?: 'User', company?: string | null, email?: string | null, first_name?: string | null, has_stardog_free?: boolean | null, id?: string | null, is_databricks_user?: boolean | null, is_staff?: boolean | null, is_voicebox_enabled?: boolean | null, is_static_voicebox?: boolean | null, is_verified?: boolean | null, last_login?: string | null, last_name?: string | null, title?: string | null } | null> | null, getUserSearchDetails?: { __typename?: 'UserSearchDetails', total?: number | null } | null };

export type SettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingsQuery = { __typename?: 'Query', settings: { __typename?: 'Settings', auth0Auth: boolean, azureAuth: boolean, baseURL: string, explorerVersion: string, designerVersion: string, friendlyName: string, geoaxisAuth: boolean, googleAuth: boolean, keycloakAuth: boolean, passwordAuth: boolean, portal: boolean, openidAuth: boolean, stardogEndpoint: string, studioVersion: string, dataMarketplace: boolean, homeFooterLinks: boolean } };

export type TrackEventMutationVariables = Exact<{
  input: TrackEventInput;
}>;


export type TrackEventMutation = { __typename?: 'Mutation', trackEvent?: { __typename?: 'GenericResponse', success: boolean, error?: string | null } | null };

export type UpdatePartnerConnectionMutationVariables = Exact<{
  input: UpdatePartnerConnectionInput;
}>;


export type UpdatePartnerConnectionMutation = { __typename?: 'Mutation', updatePartnerConnection?: { __typename?: 'GenericResponse', success: boolean, error?: string | null } | null };

export type UpdateProfileMutationVariables = Exact<{
  input: ProfileInput;
}>;


export type UpdateProfileMutation = { __typename?: 'Mutation', updateProfile?: { __typename?: 'User', id?: string | null, username: string, is_authenticated: boolean, title?: string | null, company?: string | null, phone?: string | null, use_case?: string | null, email?: string | null, familiarity_with_kgs?: string | null, best_describes_role?: string | null, industry?: string | null, best_describes_company?: string | null } | null };

export type UpdateUserFeaturesMutationVariables = Exact<{
  user_id: Scalars['ID'];
  input: UserFeaturesInput;
}>;


export type UpdateUserFeaturesMutation = { __typename?: 'Mutation', updateUserFeatures?: { __typename?: 'User', id?: string | null, is_voicebox_enabled?: boolean | null, is_static_voicebox?: boolean | null } | null };

export type UpdateVoiceboxAppMutationVariables = Exact<{
  input: UpdateVoiceboxAppInput;
}>;


export type UpdateVoiceboxAppMutation = { __typename?: 'Mutation', updateVoiceboxApp?: { __typename?: 'GenericResponse', success: boolean, error?: string | null } | null };

export type UpgradeCloudMutationVariables = Exact<{
  item: CheckoutLineItem;
  connectionId: Scalars['ID'];
}>;


export type UpgradeCloudMutation = { __typename?: 'Mutation', upgradeCloud?: { __typename?: 'BillingSession', url?: string | null } | null };

export type UserPartnerConnectionsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserPartnerConnectionsQuery = { __typename?: 'Query', profile?: { __typename?: 'User', first_name?: string | null, last_name?: string | null, email?: string | null, username: string } | null, userPartnerConnections?: Array<{ __typename?: 'PartnerConnectionDetail', databricks_workspace_id?: string | null, jdbc_url?: string | null, workspace_url?: string | null, user_email?: string | null, connection_id?: string | null, user_first_name?: string | null, is_configured_resources?: boolean | null, databricks_connection_name?: string | null, databricks_personal_token_id?: string | null, http_path?: string | null, cluster_id?: string | null, stardog_server_connection?: { __typename?: 'Connection', name: string, id: string, isStardogCloud?: boolean | null, index: number, endpoint: string } | null } | null> | null };

export type VerifyInvitationMutationVariables = Exact<{
  input: InvitationFlagInput;
}>;


export type VerifyInvitationMutation = { __typename?: 'Mutation', verifyInvitation?: { __typename?: 'GenericResponse', success: boolean, error?: string | null } | null };


export const AcceptInvitationDocument = `
    mutation acceptInvitation($input: InvitationFlagInput!) {
  acceptInvitation(input: $input) {
    success
    error
  }
}
    `;
export const useAcceptInvitationMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<AcceptInvitationMutation, TError, AcceptInvitationMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<AcceptInvitationMutation, TError, AcceptInvitationMutationVariables, TContext>(
      ['acceptInvitation'],
      (variables?: AcceptInvitationMutationVariables) => fetcher<AcceptInvitationMutation, AcceptInvitationMutationVariables>(client, AcceptInvitationDocument, variables, headers)(),
      options
    );
export const AddConnectionDocument = `
    mutation addConnection($input: AddConnectionInput!) {
  addConnection(input: $input) {
    id
    index
    name
    endpoint
    username
    token
    useBrowserAuth
    useSSO
  }
}
    `;
export const useAddConnectionMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<AddConnectionMutation, TError, AddConnectionMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<AddConnectionMutation, TError, AddConnectionMutationVariables, TContext>(
      ['addConnection'],
      (variables?: AddConnectionMutationVariables) => fetcher<AddConnectionMutation, AddConnectionMutationVariables>(client, AddConnectionDocument, variables, headers)(),
      options
    );
export const AddInvitationDocument = `
    mutation addInvitation($input: InvitationInput!) {
  addInvitation(input: $input) {
    success
    error
  }
}
    `;
export const useAddInvitationMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<AddInvitationMutation, TError, AddInvitationMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<AddInvitationMutation, TError, AddInvitationMutationVariables, TContext>(
      ['addInvitation'],
      (variables?: AddInvitationMutationVariables) => fetcher<AddInvitationMutation, AddInvitationMutationVariables>(client, AddInvitationDocument, variables, headers)(),
      options
    );
export const AddShareDocument = `
    mutation addShare($input: ShareInput!) {
  addShare(input: $input) {
    short_url
    target_path
    endpoint
    service
    expiration
  }
}
    `;
export const useAddShareMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<AddShareMutation, TError, AddShareMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<AddShareMutation, TError, AddShareMutationVariables, TContext>(
      ['addShare'],
      (variables?: AddShareMutationVariables) => fetcher<AddShareMutation, AddShareMutationVariables>(client, AddShareDocument, variables, headers)(),
      options
    );
export const CancelCloudDocument = `
    mutation cancelCloud($input: CancelCloudInput!) {
  cancelCloud(input: $input) {
    success
    error
  }
}
    `;
export const useCancelCloudMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CancelCloudMutation, TError, CancelCloudMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CancelCloudMutation, TError, CancelCloudMutationVariables, TContext>(
      ['cancelCloud'],
      (variables?: CancelCloudMutationVariables) => fetcher<CancelCloudMutation, CancelCloudMutationVariables>(client, CancelCloudDocument, variables, headers)(),
      options
    );
export const CheckCloudDocument = `
    query checkCloud {
  checkCloudQueue {
    small_count
  }
  listConnections {
    name
  }
  profile {
    stripe_customer {
      cloud_quota {
        total
        available
      }
      is_cloud_quota_breached
    }
  }
}
    `;
export const useCheckCloudQuery = <
      TData = CheckCloudQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: CheckCloudQueryVariables,
      options?: UseQueryOptions<CheckCloudQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<CheckCloudQuery, TError, TData>(
      variables === undefined ? ['checkCloud'] : ['checkCloud', variables],
      fetcher<CheckCloudQuery, CheckCloudQueryVariables>(client, CheckCloudDocument, variables, headers),
      options
    );
useCheckCloudQuery.document = CheckCloudDocument;


useCheckCloudQuery.getKey = (variables?: CheckCloudQueryVariables) => variables === undefined ? ['checkCloud'] : ['checkCloud', variables];
;

export const CheckoutCartDocument = `
    mutation checkoutCart($item: CheckoutLineItem!, $addOns: [CheckoutLineItem]) {
  checkoutCart(item: $item, addOns: $addOns) {
    url
  }
}
    `;
export const useCheckoutCartMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CheckoutCartMutation, TError, CheckoutCartMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CheckoutCartMutation, TError, CheckoutCartMutationVariables, TContext>(
      ['checkoutCart'],
      (variables?: CheckoutCartMutationVariables) => fetcher<CheckoutCartMutation, CheckoutCartMutationVariables>(client, CheckoutCartDocument, variables, headers)(),
      options
    );
export const CreateApiTokenDocument = `
    mutation createApiToken($input: CreateApiTokenInput!) {
  createApiToken(input: $input) {
    secret
  }
}
    `;
export const useCreateApiTokenMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreateApiTokenMutation, TError, CreateApiTokenMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CreateApiTokenMutation, TError, CreateApiTokenMutationVariables, TContext>(
      ['createApiToken'],
      (variables?: CreateApiTokenMutationVariables) => fetcher<CreateApiTokenMutation, CreateApiTokenMutationVariables>(client, CreateApiTokenDocument, variables, headers)(),
      options
    );
export const CreateVoiceboxAppDocument = `
    mutation createVoiceboxApp($input: CreateVoiceboxAppInput!) {
  createVoiceboxApp(input: $input) {
    success
    error
  }
}
    `;
export const useCreateVoiceboxAppMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreateVoiceboxAppMutation, TError, CreateVoiceboxAppMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<CreateVoiceboxAppMutation, TError, CreateVoiceboxAppMutationVariables, TContext>(
      ['createVoiceboxApp'],
      (variables?: CreateVoiceboxAppMutationVariables) => fetcher<CreateVoiceboxAppMutation, CreateVoiceboxAppMutationVariables>(client, CreateVoiceboxAppDocument, variables, headers)(),
      options
    );
export const DeleteAccountDocument = `
    mutation deleteAccount {
  deleteAccount {
    success
    error
  }
}
    `;
export const useDeleteAccountMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeleteAccountMutation, TError, DeleteAccountMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<DeleteAccountMutation, TError, DeleteAccountMutationVariables, TContext>(
      ['deleteAccount'],
      (variables?: DeleteAccountMutationVariables) => fetcher<DeleteAccountMutation, DeleteAccountMutationVariables>(client, DeleteAccountDocument, variables, headers)(),
      options
    );
export const DeleteApiTokenDocument = `
    mutation deleteApiToken($input: DeleteApiTokenInput!) {
  deleteApiToken(input: $input) {
    success
    error
  }
}
    `;
export const useDeleteApiTokenMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeleteApiTokenMutation, TError, DeleteApiTokenMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<DeleteApiTokenMutation, TError, DeleteApiTokenMutationVariables, TContext>(
      ['deleteApiToken'],
      (variables?: DeleteApiTokenMutationVariables) => fetcher<DeleteApiTokenMutation, DeleteApiTokenMutationVariables>(client, DeleteApiTokenDocument, variables, headers)(),
      options
    );
export const DeleteCloudDocument = `
    mutation deleteCloud($input: CloudCleanupInput!) {
  deleteCloud(input: $input) {
    success
    error
  }
}
    `;
export const useDeleteCloudMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeleteCloudMutation, TError, DeleteCloudMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<DeleteCloudMutation, TError, DeleteCloudMutationVariables, TContext>(
      ['deleteCloud'],
      (variables?: DeleteCloudMutationVariables) => fetcher<DeleteCloudMutation, DeleteCloudMutationVariables>(client, DeleteCloudDocument, variables, headers)(),
      options
    );
export const DeleteConnectionDocument = `
    mutation deleteConnection($name: String!) {
  deleteConnection(name: $name) {
    success
    error
  }
}
    `;
export const useDeleteConnectionMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeleteConnectionMutation, TError, DeleteConnectionMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<DeleteConnectionMutation, TError, DeleteConnectionMutationVariables, TContext>(
      ['deleteConnection'],
      (variables?: DeleteConnectionMutationVariables) => fetcher<DeleteConnectionMutation, DeleteConnectionMutationVariables>(client, DeleteConnectionDocument, variables, headers)(),
      options
    );
export const DeleteVoiceboxAppDocument = `
    mutation deleteVoiceboxApp($id: ID!) {
  deleteVoiceboxApp(id: $id) {
    success
    error
  }
}
    `;
export const useDeleteVoiceboxAppMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeleteVoiceboxAppMutation, TError, DeleteVoiceboxAppMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<DeleteVoiceboxAppMutation, TError, DeleteVoiceboxAppMutationVariables, TContext>(
      ['deleteVoiceboxApp'],
      (variables?: DeleteVoiceboxAppMutationVariables) => fetcher<DeleteVoiceboxAppMutation, DeleteVoiceboxAppMutationVariables>(client, DeleteVoiceboxAppDocument, variables, headers)(),
      options
    );
export const DeleteVoiceboxConversationDocument = `
    mutation deleteVoiceboxConversation($conversation_id: ID!) {
  deleteVoiceboxConversation(conversation_id: $conversation_id) {
    success
    error
  }
}
    `;
export const useDeleteVoiceboxConversationMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeleteVoiceboxConversationMutation, TError, DeleteVoiceboxConversationMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<DeleteVoiceboxConversationMutation, TError, DeleteVoiceboxConversationMutationVariables, TContext>(
      ['deleteVoiceboxConversation'],
      (variables?: DeleteVoiceboxConversationMutationVariables) => fetcher<DeleteVoiceboxConversationMutation, DeleteVoiceboxConversationMutationVariables>(client, DeleteVoiceboxConversationDocument, variables, headers)(),
      options
    );
export const EditConnectionDocument = `
    mutation editConnection($input: EditConnectionInput!) {
  editConnection(input: $input) {
    id
    index
    name
    endpoint
    username
    token
    useBrowserAuth
    useSSO
  }
}
    `;
export const useEditConnectionMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<EditConnectionMutation, TError, EditConnectionMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<EditConnectionMutation, TError, EditConnectionMutationVariables, TContext>(
      ['editConnection'],
      (variables?: EditConnectionMutationVariables) => fetcher<EditConnectionMutation, EditConnectionMutationVariables>(client, EditConnectionDocument, variables, headers)(),
      options
    );
export const EditVoiceboxConversationDocument = `
    mutation editVoiceboxConversation($input: EditVoiceboxConversationInput!) {
  editVoiceboxConversation(input: $input) {
    success
    error
  }
}
    `;
export const useEditVoiceboxConversationMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<EditVoiceboxConversationMutation, TError, EditVoiceboxConversationMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<EditVoiceboxConversationMutation, TError, EditVoiceboxConversationMutationVariables, TContext>(
      ['editVoiceboxConversation'],
      (variables?: EditVoiceboxConversationMutationVariables) => fetcher<EditVoiceboxConversationMutation, EditVoiceboxConversationMutationVariables>(client, EditVoiceboxConversationDocument, variables, headers)(),
      options
    );
export const GenerateConfigurationDocument = `
    mutation generateConfiguration($endpoint: String!) {
  generateConfiguration(endpoint: $endpoint) {
    id
    jwt_config
    properties
  }
}
    `;
export const useGenerateConfigurationMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<GenerateConfigurationMutation, TError, GenerateConfigurationMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<GenerateConfigurationMutation, TError, GenerateConfigurationMutationVariables, TContext>(
      ['generateConfiguration'],
      (variables?: GenerateConfigurationMutationVariables) => fetcher<GenerateConfigurationMutation, GenerateConfigurationMutationVariables>(client, GenerateConfigurationDocument, variables, headers)(),
      options
    );
export const GenerateTokenDocument = `
    query generateToken($endpoint: String!) {
  generateToken(endpoint: $endpoint) {
    access_token
    user {
      username
    }
  }
}
    `;
export const useGenerateTokenQuery = <
      TData = GenerateTokenQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GenerateTokenQueryVariables,
      options?: UseQueryOptions<GenerateTokenQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GenerateTokenQuery, TError, TData>(
      ['generateToken', variables],
      fetcher<GenerateTokenQuery, GenerateTokenQueryVariables>(client, GenerateTokenDocument, variables, headers),
      options
    );
useGenerateTokenQuery.document = GenerateTokenDocument;


useGenerateTokenQuery.getKey = (variables: GenerateTokenQueryVariables) => ['generateToken', variables];
;

export const GetConnectionByIndexDocument = `
    query getConnectionByIndex($index: Int!) {
  connection: getConnectionByIndex(index: $index) {
    id
    index
    name
    cloud {
      id
      flavor {
        name
      }
      owner {
        id
        email
        is_authenticated
        username
      }
    }
    endpoint
    username
    token
    useBrowserAuth
    useSSO
    isStardogFree
    isStardogCloud
    isAllocating
    shouldShowDesigner
    stripeSubscription {
      url
      status
      isWaitingForPayment
    }
    stripeSubscriptionOrder {
      payment_system
      trial_days_remaining
      trial_days_total
    }
  }
}
    `;
export const useGetConnectionByIndexQuery = <
      TData = GetConnectionByIndexQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetConnectionByIndexQueryVariables,
      options?: UseQueryOptions<GetConnectionByIndexQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetConnectionByIndexQuery, TError, TData>(
      ['getConnectionByIndex', variables],
      fetcher<GetConnectionByIndexQuery, GetConnectionByIndexQueryVariables>(client, GetConnectionByIndexDocument, variables, headers),
      options
    );
useGetConnectionByIndexQuery.document = GetConnectionByIndexDocument;


useGetConnectionByIndexQuery.getKey = (variables: GetConnectionByIndexQueryVariables) => ['getConnectionByIndex', variables];
;

export const GetCustomerSsoSettingsDocument = `
    query getCustomerSsoSettings {
  customerSsoSettings {
    azureProviders {
      customerName
    }
  }
}
    `;
export const useGetCustomerSsoSettingsQuery = <
      TData = GetCustomerSsoSettingsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetCustomerSsoSettingsQueryVariables,
      options?: UseQueryOptions<GetCustomerSsoSettingsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetCustomerSsoSettingsQuery, TError, TData>(
      variables === undefined ? ['getCustomerSsoSettings'] : ['getCustomerSsoSettings', variables],
      fetcher<GetCustomerSsoSettingsQuery, GetCustomerSsoSettingsQueryVariables>(client, GetCustomerSsoSettingsDocument, variables, headers),
      options
    );
useGetCustomerSsoSettingsQuery.document = GetCustomerSsoSettingsDocument;


useGetCustomerSsoSettingsQuery.getKey = (variables?: GetCustomerSsoSettingsQueryVariables) => variables === undefined ? ['getCustomerSsoSettings'] : ['getCustomerSsoSettings', variables];
;

export const GetInvitationDocument = `
    query getInvitation($id: ID!) {
  getInvitation(id: $id) {
    id
    email
    endpoint
    role
    created
    expires
    accepted
    sender {
      email
      first_name
      last_name
    }
    existing_user {
      email
      first_name
      last_name
    }
  }
}
    `;
export const useGetInvitationQuery = <
      TData = GetInvitationQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetInvitationQueryVariables,
      options?: UseQueryOptions<GetInvitationQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetInvitationQuery, TError, TData>(
      ['getInvitation', variables],
      fetcher<GetInvitationQuery, GetInvitationQueryVariables>(client, GetInvitationDocument, variables, headers),
      options
    );
useGetInvitationQuery.document = GetInvitationDocument;


useGetInvitationQuery.getKey = (variables: GetInvitationQueryVariables) => ['getInvitation', variables];
;

export const MarketplaceSettingsDocument = `
    query marketplaceSettings {
  marketplaceSettings {
    marketplaceUsername
    marketplacePassword
    marketplaceEndpoint
    marketplaceDatabase
  }
}
    `;
export const useMarketplaceSettingsQuery = <
      TData = MarketplaceSettingsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: MarketplaceSettingsQueryVariables,
      options?: UseQueryOptions<MarketplaceSettingsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<MarketplaceSettingsQuery, TError, TData>(
      variables === undefined ? ['marketplaceSettings'] : ['marketplaceSettings', variables],
      fetcher<MarketplaceSettingsQuery, MarketplaceSettingsQueryVariables>(client, MarketplaceSettingsDocument, variables, headers),
      options
    );
useMarketplaceSettingsQuery.document = MarketplaceSettingsDocument;


useMarketplaceSettingsQuery.getKey = (variables?: MarketplaceSettingsQueryVariables) => variables === undefined ? ['marketplaceSettings'] : ['marketplaceSettings', variables];
;

export const GetProductsDocument = `
    query getProducts {
  getStripePrices {
    id
    nickname
    amount
    stardog_billing_type
    product_info {
      name
      description
      metadata {
        flavor
      }
    }
  }
  checkCloudQueue {
    small_count
    medium_count
    micro_count
  }
  profile {
    has_stardog_free
    is_staff
    stripe_customer {
      cloud_quota {
        total
        available
      }
      is_cloud_quota_breached
    }
  }
}
    `;
export const useGetProductsQuery = <
      TData = GetProductsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetProductsQueryVariables,
      options?: UseQueryOptions<GetProductsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetProductsQuery, TError, TData>(
      variables === undefined ? ['getProducts'] : ['getProducts', variables],
      fetcher<GetProductsQuery, GetProductsQueryVariables>(client, GetProductsDocument, variables, headers),
      options
    );
useGetProductsQuery.document = GetProductsDocument;


useGetProductsQuery.getKey = (variables?: GetProductsQueryVariables) => variables === undefined ? ['getProducts'] : ['getProducts', variables];
;

export const GetReportDocument = `
    query getReport($inactiveDays: Int) {
  listStardogCloud(inactive_days: $inactiveDays) {
    id
    name
    status
    endpoint
    flavor {
      name
    }
    owner {
      username
      last_login
    }
  }
  checkCloudQueue {
    small_count
    medium_count
    micro_count
  }
}
    `;
export const useGetReportQuery = <
      TData = GetReportQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetReportQueryVariables,
      options?: UseQueryOptions<GetReportQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetReportQuery, TError, TData>(
      variables === undefined ? ['getReport'] : ['getReport', variables],
      fetcher<GetReportQuery, GetReportQueryVariables>(client, GetReportDocument, variables, headers),
      options
    );
useGetReportQuery.document = GetReportDocument;


useGetReportQuery.getKey = (variables?: GetReportQueryVariables) => variables === undefined ? ['getReport'] : ['getReport', variables];
;

export const GetShareByShortHashDocument = `
    query getShareByShortHash($shortHash: String!) {
  getShareByShortHash(shortHash: $shortHash) {
    target_path
    endpoint
    service
    last_accessed
  }
}
    `;
export const useGetShareByShortHashQuery = <
      TData = GetShareByShortHashQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetShareByShortHashQueryVariables,
      options?: UseQueryOptions<GetShareByShortHashQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetShareByShortHashQuery, TError, TData>(
      ['getShareByShortHash', variables],
      fetcher<GetShareByShortHashQuery, GetShareByShortHashQueryVariables>(client, GetShareByShortHashDocument, variables, headers),
      options
    );
useGetShareByShortHashQuery.document = GetShareByShortHashDocument;


useGetShareByShortHashQuery.getKey = (variables: GetShareByShortHashQueryVariables) => ['getShareByShortHash', variables];
;

export const GetStripeSessionUrlDocument = `
    mutation getStripeSessionUrl {
  getStripeSessionUrl {
    url
  }
}
    `;
export const useGetStripeSessionUrlMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<GetStripeSessionUrlMutation, TError, GetStripeSessionUrlMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<GetStripeSessionUrlMutation, TError, GetStripeSessionUrlMutationVariables, TContext>(
      ['getStripeSessionUrl'],
      (variables?: GetStripeSessionUrlMutationVariables) => fetcher<GetStripeSessionUrlMutation, GetStripeSessionUrlMutationVariables>(client, GetStripeSessionUrlDocument, variables, headers)(),
      options
    );
export const GetUserCurrentPartnerConnectionDocument = `
    query getUserCurrentPartnerConnection {
  profile {
    first_name
    last_name
    email
    username
  }
  getUserCurrentPartnerConnection {
    databricks_workspace_id
    jdbc_url
    workspace_url
    user_email
    connection_id
    user_first_name
    is_configured_resources
    databricks_connection_name
    stardog_server_connection {
      name
      id
      isStardogCloud
      index
      endpoint
    }
    databricks_personal_token_id
    cluster_id
  }
}
    `;
export const useGetUserCurrentPartnerConnectionQuery = <
      TData = GetUserCurrentPartnerConnectionQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetUserCurrentPartnerConnectionQueryVariables,
      options?: UseQueryOptions<GetUserCurrentPartnerConnectionQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetUserCurrentPartnerConnectionQuery, TError, TData>(
      variables === undefined ? ['getUserCurrentPartnerConnection'] : ['getUserCurrentPartnerConnection', variables],
      fetcher<GetUserCurrentPartnerConnectionQuery, GetUserCurrentPartnerConnectionQueryVariables>(client, GetUserCurrentPartnerConnectionDocument, variables, headers),
      options
    );
useGetUserCurrentPartnerConnectionQuery.document = GetUserCurrentPartnerConnectionDocument;


useGetUserCurrentPartnerConnectionQuery.getKey = (variables?: GetUserCurrentPartnerConnectionQueryVariables) => variables === undefined ? ['getUserCurrentPartnerConnection'] : ['getUserCurrentPartnerConnection', variables];
;

export const GetUserInfoDocument = `
    query getUserInfo($user_id: String!) {
  getUser(user_id: $user_id) {
    company
    email
    first_name
    has_stardog_free
    id
    is_databricks_user
    is_staff
    is_voicebox_enabled
    is_static_voicebox
    is_verified
    is_authenticated
    last_login
    last_name
    phone
    title
    use_case
    date_joined
  }
  getUserConnections(user_id: $user_id) {
    id
    name
    endpoint
    useSSO
    type
  }
  getUserClouds(user_id: $user_id) {
    created
    endpoint
    flavor {
      name
    }
    id
    name
    status
    region
    updated
    payment_ref
  }
  getUserArchivedClouds(user_id: $user_id) {
    created
    endpoint
    flavor
    id
    name
    payment_ref
    region
  }
  grafanaHighLevelDashboardSettings {
    id
    slug
  }
}
    `;
export const useGetUserInfoQuery = <
      TData = GetUserInfoQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetUserInfoQueryVariables,
      options?: UseQueryOptions<GetUserInfoQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetUserInfoQuery, TError, TData>(
      ['getUserInfo', variables],
      fetcher<GetUserInfoQuery, GetUserInfoQueryVariables>(client, GetUserInfoDocument, variables, headers),
      options
    );
useGetUserInfoQuery.document = GetUserInfoDocument;


useGetUserInfoQuery.getKey = (variables: GetUserInfoQueryVariables) => ['getUserInfo', variables];
;

export const GetVoiceboxConversationDocument = `
    query getVoiceboxConversation($conversation_id: String!) {
  getVoiceboxConversation(conversation_id: $conversation_id) {
    id
    message_history {
      id
      content
      sender
      created
    }
  }
}
    `;
export const useGetVoiceboxConversationQuery = <
      TData = GetVoiceboxConversationQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetVoiceboxConversationQueryVariables,
      options?: UseQueryOptions<GetVoiceboxConversationQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetVoiceboxConversationQuery, TError, TData>(
      ['getVoiceboxConversation', variables],
      fetcher<GetVoiceboxConversationQuery, GetVoiceboxConversationQueryVariables>(client, GetVoiceboxConversationDocument, variables, headers),
      options
    );
useGetVoiceboxConversationQuery.document = GetVoiceboxConversationDocument;


useGetVoiceboxConversationQuery.getKey = (variables: GetVoiceboxConversationQueryVariables) => ['getVoiceboxConversation', variables];
;

export const ListApiTokensDocument = `
    query listApiTokens($app_id: ID, $paging: PagingInput) {
  listApiTokens(app_id: $app_id, paging: $paging) {
    created
    expires
    id
    last_used
    name
  }
  apiTokenCount(app_id: $app_id) {
    count
  }
}
    `;
export const useListApiTokensQuery = <
      TData = ListApiTokensQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: ListApiTokensQueryVariables,
      options?: UseQueryOptions<ListApiTokensQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<ListApiTokensQuery, TError, TData>(
      variables === undefined ? ['listApiTokens'] : ['listApiTokens', variables],
      fetcher<ListApiTokensQuery, ListApiTokensQueryVariables>(client, ListApiTokensDocument, variables, headers),
      options
    );
useListApiTokensQuery.document = ListApiTokensDocument;


useListApiTokensQuery.getKey = (variables?: ListApiTokensQueryVariables) => variables === undefined ? ['listApiTokens'] : ['listApiTokens', variables];
;

export const ListConnectionsByEndpointDocument = `
    query listConnectionsByEndpoint($endpoint: String!) {
  listConnectionsByEndpoint(endpoint: $endpoint) {
    id
    index
    name
    endpoint
    cloud {
      id
    }
    username
    token
    useBrowserAuth
    useSSO
    isStardogFree
    isStardogCloud
    isAllocating
    stripeSubscription {
      url
      status
      isWaitingForPayment
      isExpired
    }
  }
}
    `;
export const useListConnectionsByEndpointQuery = <
      TData = ListConnectionsByEndpointQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: ListConnectionsByEndpointQueryVariables,
      options?: UseQueryOptions<ListConnectionsByEndpointQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<ListConnectionsByEndpointQuery, TError, TData>(
      ['listConnectionsByEndpoint', variables],
      fetcher<ListConnectionsByEndpointQuery, ListConnectionsByEndpointQueryVariables>(client, ListConnectionsByEndpointDocument, variables, headers),
      options
    );
useListConnectionsByEndpointQuery.document = ListConnectionsByEndpointDocument;


useListConnectionsByEndpointQuery.getKey = (variables: ListConnectionsByEndpointQueryVariables) => ['listConnectionsByEndpoint', variables];
;

export const ListVoiceboxAppsDocument = `
    query listVoiceboxApps {
  listVoiceboxApps {
    id
    name
    connection_id
    database
    model
    named_graphs
    reasoning
  }
}
    `;
export const useListVoiceboxAppsQuery = <
      TData = ListVoiceboxAppsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: ListVoiceboxAppsQueryVariables,
      options?: UseQueryOptions<ListVoiceboxAppsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<ListVoiceboxAppsQuery, TError, TData>(
      variables === undefined ? ['listVoiceboxApps'] : ['listVoiceboxApps', variables],
      fetcher<ListVoiceboxAppsQuery, ListVoiceboxAppsQueryVariables>(client, ListVoiceboxAppsDocument, variables, headers),
      options
    );
useListVoiceboxAppsQuery.document = ListVoiceboxAppsDocument;


useListVoiceboxAppsQuery.getKey = (variables?: ListVoiceboxAppsQueryVariables) => variables === undefined ? ['listVoiceboxApps'] : ['listVoiceboxApps', variables];
;

export const ListVoiceboxConversationsDocument = `
    query listVoiceboxConversations($paging: PagingInput, $filterInput: VoiceboxConversationsFilterInput) {
  listVoiceboxConversations(paging: $paging, filterInput: $filterInput) {
    id
    name
    created
    first_user_message {
      content
      user_message_context {
        app
      }
    }
    last_user_message {
      content
      created
      user_message_context {
        connection_id
      }
    }
  }
  voiceboxConversationCount(filterInput: $filterInput) {
    count
  }
}
    `;
export const useListVoiceboxConversationsQuery = <
      TData = ListVoiceboxConversationsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: ListVoiceboxConversationsQueryVariables,
      options?: UseQueryOptions<ListVoiceboxConversationsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<ListVoiceboxConversationsQuery, TError, TData>(
      variables === undefined ? ['listVoiceboxConversations'] : ['listVoiceboxConversations', variables],
      fetcher<ListVoiceboxConversationsQuery, ListVoiceboxConversationsQueryVariables>(client, ListVoiceboxConversationsDocument, variables, headers),
      options
    );
useListVoiceboxConversationsQuery.document = ListVoiceboxConversationsDocument;


useListVoiceboxConversationsQuery.getKey = (variables?: ListVoiceboxConversationsQueryVariables) => variables === undefined ? ['listVoiceboxConversations'] : ['listVoiceboxConversations', variables];
;

export const ProfileDocument = `
    query profile {
  profile {
    id
    is_superuser
    is_staff
    has_updated_profile
    is_ephemeral
    is_authenticated
    is_verified
    is_voicebox_enabled
    is_static_voicebox
    username
    first_name
    last_name
    email
    company
    title
    phone
    use_case
    stripe_customer {
      customer_id
    }
    is_databricks_user
    userflow_signature
    date_joined
    familiarity_with_kgs
    best_describes_role
    industry
    best_describes_company
  }
}
    `;
export const useProfileQuery = <
      TData = ProfileQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: ProfileQueryVariables,
      options?: UseQueryOptions<ProfileQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<ProfileQuery, TError, TData>(
      variables === undefined ? ['profile'] : ['profile', variables],
      fetcher<ProfileQuery, ProfileQueryVariables>(client, ProfileDocument, variables, headers),
      options
    );
useProfileQuery.document = ProfileDocument;


useProfileQuery.getKey = (variables?: ProfileQueryVariables) => variables === undefined ? ['profile'] : ['profile', variables];
;

export const RemovePartnerConnectionDocument = `
    mutation removePartnerConnection($input: RemovePartnerConnectionInput!) {
  removePartnerConnection(input: $input) {
    success
    error
  }
}
    `;
export const useRemovePartnerConnectionMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<RemovePartnerConnectionMutation, TError, RemovePartnerConnectionMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<RemovePartnerConnectionMutation, TError, RemovePartnerConnectionMutationVariables, TContext>(
      ['removePartnerConnection'],
      (variables?: RemovePartnerConnectionMutationVariables) => fetcher<RemovePartnerConnectionMutation, RemovePartnerConnectionMutationVariables>(client, RemovePartnerConnectionDocument, variables, headers)(),
      options
    );
export const ResendEmailDocument = `
    mutation resendEmail {
  resendEmail {
    success
    error
  }
}
    `;
export const useResendEmailMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ResendEmailMutation, TError, ResendEmailMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<ResendEmailMutation, TError, ResendEmailMutationVariables, TContext>(
      ['resendEmail'],
      (variables?: ResendEmailMutationVariables) => fetcher<ResendEmailMutation, ResendEmailMutationVariables>(client, ResendEmailDocument, variables, headers)(),
      options
    );
export const SavedConnectionsDocument = `
    query savedConnections {
  listConnections {
    id
    index
    name
    endpoint
    cloud {
      id
      owner {
        email
        username
        is_authenticated
      }
      flavor {
        name
      }
    }
    username
    token
    useBrowserAuth
    useSSO
    isStardogFree
    isStardogCloud
    isAllocating
    stripeSubscription {
      url
      status
      isWaitingForPayment
      isExpired
    }
  }
}
    `;
export const useSavedConnectionsQuery = <
      TData = SavedConnectionsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: SavedConnectionsQueryVariables,
      options?: UseQueryOptions<SavedConnectionsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<SavedConnectionsQuery, TError, TData>(
      variables === undefined ? ['savedConnections'] : ['savedConnections', variables],
      fetcher<SavedConnectionsQuery, SavedConnectionsQueryVariables>(client, SavedConnectionsDocument, variables, headers),
      options
    );
useSavedConnectionsQuery.document = SavedConnectionsDocument;


useSavedConnectionsQuery.getKey = (variables?: SavedConnectionsQueryVariables) => variables === undefined ? ['savedConnections'] : ['savedConnections', variables];
;

export const GetUserSearchResultsDocument = `
    query getUserSearchResults($token: String!, $filters: UserSearchFiltersInput, $offset: Int!, $limit: Int!) {
  searchUsers(token: $token, filters: $filters, offset: $offset, limit: $limit) {
    company
    email
    first_name
    has_stardog_free
    id
    is_databricks_user
    is_staff
    is_voicebox_enabled
    is_static_voicebox
    is_verified
    last_login
    last_name
    title
  }
  getUserSearchDetails(token: $token, filters: $filters) {
    total
  }
}
    `;
export const useGetUserSearchResultsQuery = <
      TData = GetUserSearchResultsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetUserSearchResultsQueryVariables,
      options?: UseQueryOptions<GetUserSearchResultsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<GetUserSearchResultsQuery, TError, TData>(
      ['getUserSearchResults', variables],
      fetcher<GetUserSearchResultsQuery, GetUserSearchResultsQueryVariables>(client, GetUserSearchResultsDocument, variables, headers),
      options
    );
useGetUserSearchResultsQuery.document = GetUserSearchResultsDocument;


useGetUserSearchResultsQuery.getKey = (variables: GetUserSearchResultsQueryVariables) => ['getUserSearchResults', variables];
;

export const SettingsDocument = `
    query settings {
  settings {
    auth0Auth
    azureAuth
    baseURL
    explorerVersion
    designerVersion
    friendlyName
    geoaxisAuth
    googleAuth
    keycloakAuth
    passwordAuth
    portal
    openidAuth
    stardogEndpoint
    studioVersion
    dataMarketplace
    homeFooterLinks
  }
}
    `;
export const useSettingsQuery = <
      TData = SettingsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: SettingsQueryVariables,
      options?: UseQueryOptions<SettingsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<SettingsQuery, TError, TData>(
      variables === undefined ? ['settings'] : ['settings', variables],
      fetcher<SettingsQuery, SettingsQueryVariables>(client, SettingsDocument, variables, headers),
      options
    );
useSettingsQuery.document = SettingsDocument;


useSettingsQuery.getKey = (variables?: SettingsQueryVariables) => variables === undefined ? ['settings'] : ['settings', variables];
;

export const TrackEventDocument = `
    mutation trackEvent($input: TrackEventInput!) {
  trackEvent(input: $input) {
    success
    error
  }
}
    `;
export const useTrackEventMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<TrackEventMutation, TError, TrackEventMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<TrackEventMutation, TError, TrackEventMutationVariables, TContext>(
      ['trackEvent'],
      (variables?: TrackEventMutationVariables) => fetcher<TrackEventMutation, TrackEventMutationVariables>(client, TrackEventDocument, variables, headers)(),
      options
    );
export const UpdatePartnerConnectionDocument = `
    mutation updatePartnerConnection($input: UpdatePartnerConnectionInput!) {
  updatePartnerConnection(input: $input) {
    success
    error
  }
}
    `;
export const useUpdatePartnerConnectionMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdatePartnerConnectionMutation, TError, UpdatePartnerConnectionMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UpdatePartnerConnectionMutation, TError, UpdatePartnerConnectionMutationVariables, TContext>(
      ['updatePartnerConnection'],
      (variables?: UpdatePartnerConnectionMutationVariables) => fetcher<UpdatePartnerConnectionMutation, UpdatePartnerConnectionMutationVariables>(client, UpdatePartnerConnectionDocument, variables, headers)(),
      options
    );
export const UpdateProfileDocument = `
    mutation updateProfile($input: ProfileInput!) {
  updateProfile(input: $input) {
    id
    username
    is_authenticated
    title
    company
    phone
    use_case
    email
    familiarity_with_kgs
    best_describes_role
    industry
    best_describes_company
  }
}
    `;
export const useUpdateProfileMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateProfileMutation, TError, UpdateProfileMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UpdateProfileMutation, TError, UpdateProfileMutationVariables, TContext>(
      ['updateProfile'],
      (variables?: UpdateProfileMutationVariables) => fetcher<UpdateProfileMutation, UpdateProfileMutationVariables>(client, UpdateProfileDocument, variables, headers)(),
      options
    );
export const UpdateUserFeaturesDocument = `
    mutation updateUserFeatures($user_id: ID!, $input: UserFeaturesInput!) {
  updateUserFeatures(user_id: $user_id, input: $input) {
    id
    is_voicebox_enabled
    is_static_voicebox
  }
}
    `;
export const useUpdateUserFeaturesMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateUserFeaturesMutation, TError, UpdateUserFeaturesMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UpdateUserFeaturesMutation, TError, UpdateUserFeaturesMutationVariables, TContext>(
      ['updateUserFeatures'],
      (variables?: UpdateUserFeaturesMutationVariables) => fetcher<UpdateUserFeaturesMutation, UpdateUserFeaturesMutationVariables>(client, UpdateUserFeaturesDocument, variables, headers)(),
      options
    );
export const UpdateVoiceboxAppDocument = `
    mutation updateVoiceboxApp($input: UpdateVoiceboxAppInput!) {
  updateVoiceboxApp(input: $input) {
    success
    error
  }
}
    `;
export const useUpdateVoiceboxAppMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateVoiceboxAppMutation, TError, UpdateVoiceboxAppMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UpdateVoiceboxAppMutation, TError, UpdateVoiceboxAppMutationVariables, TContext>(
      ['updateVoiceboxApp'],
      (variables?: UpdateVoiceboxAppMutationVariables) => fetcher<UpdateVoiceboxAppMutation, UpdateVoiceboxAppMutationVariables>(client, UpdateVoiceboxAppDocument, variables, headers)(),
      options
    );
export const UpgradeCloudDocument = `
    mutation upgradeCloud($item: CheckoutLineItem!, $connectionId: ID!) {
  upgradeCloud(item: $item, connectionId: $connectionId) {
    url
  }
}
    `;
export const useUpgradeCloudMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpgradeCloudMutation, TError, UpgradeCloudMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<UpgradeCloudMutation, TError, UpgradeCloudMutationVariables, TContext>(
      ['upgradeCloud'],
      (variables?: UpgradeCloudMutationVariables) => fetcher<UpgradeCloudMutation, UpgradeCloudMutationVariables>(client, UpgradeCloudDocument, variables, headers)(),
      options
    );
export const UserPartnerConnectionsDocument = `
    query userPartnerConnections {
  profile {
    first_name
    last_name
    email
    username
  }
  userPartnerConnections {
    databricks_workspace_id
    jdbc_url
    workspace_url
    user_email
    connection_id
    user_first_name
    is_configured_resources
    databricks_connection_name
    stardog_server_connection {
      name
      id
      isStardogCloud
      index
      endpoint
    }
    databricks_personal_token_id
    http_path
    cluster_id
  }
}
    `;
export const useUserPartnerConnectionsQuery = <
      TData = UserPartnerConnectionsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: UserPartnerConnectionsQueryVariables,
      options?: UseQueryOptions<UserPartnerConnectionsQuery, TError, TData>,
      headers?: RequestInit['headers']
    ) =>
    useQuery<UserPartnerConnectionsQuery, TError, TData>(
      variables === undefined ? ['userPartnerConnections'] : ['userPartnerConnections', variables],
      fetcher<UserPartnerConnectionsQuery, UserPartnerConnectionsQueryVariables>(client, UserPartnerConnectionsDocument, variables, headers),
      options
    );
useUserPartnerConnectionsQuery.document = UserPartnerConnectionsDocument;


useUserPartnerConnectionsQuery.getKey = (variables?: UserPartnerConnectionsQueryVariables) => variables === undefined ? ['userPartnerConnections'] : ['userPartnerConnections', variables];
;

export const VerifyInvitationDocument = `
    mutation verifyInvitation($input: InvitationFlagInput!) {
  verifyInvitation(input: $input) {
    success
    error
  }
}
    `;
export const useVerifyInvitationMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<VerifyInvitationMutation, TError, VerifyInvitationMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) =>
    useMutation<VerifyInvitationMutation, TError, VerifyInvitationMutationVariables, TContext>(
      ['verifyInvitation'],
      (variables?: VerifyInvitationMutationVariables) => fetcher<VerifyInvitationMutation, VerifyInvitationMutationVariables>(client, VerifyInvitationDocument, variables, headers)(),
      options
    );
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Messages } from 'src/ui/components/Messages';
import { NotFound } from 'src/ui/components/NotFound';
import { Notifications } from 'src/ui/components/Notifications';
import { PrivateRoute } from 'src/ui/components/PrivateRoute';
import { Diagnostic } from 'src/ui/containers/diagnostic/Diagnostic';
import { ConnectionDialog } from 'src/ui/containers/dialogs/ConnectionDialog';
import { AddSSOConnectionDialog } from 'src/ui/containers/launchpad/AddSSOConnection';
import { AppHooks } from 'src/ui/containers/launchpad/AppHooks';
import { AppNavBar } from 'src/ui/containers/launchpad/AppNavBar';
import { Dashboard } from 'src/ui/containers/launchpad/dashboard/Dashboard';
import { Login } from 'src/ui/containers/launchpad/Login';
import { getFriendlyName } from 'src/ui/utils/window';

/* istanbul ignore next */
export const LaunchpadApp: React.VFC = () => {
  React.useEffect(() => {
    document.title = getFriendlyName();
  }, []);

  return (
    <>
      <main>
        <AppNavBar />
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <PrivateRoute exact path="/">
            <Dashboard />
          </PrivateRoute>
          <PrivateRoute exact path="/u/:id(\d+)/">
            <Dashboard />
          </PrivateRoute>
          <PrivateRoute path="/u/:id(\d+)/diagnostic">
            <Diagnostic />
          </PrivateRoute>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </main>
      <AppHooks />
      <Notifications />
      <Messages />
      <ConnectionDialog showSSO={false} />
      <AddSSOConnectionDialog />
    </>
  );
};

import { H4 } from '@blueprintjs/core';
import * as React from 'react';
import { VoiceboxLogo } from 'vet-bones/components';
import { VoiceboxCopy, VoiceboxTestIds } from 'vet-bones/constants/voicebox';
import { getTestId, isProduction, processTestIds } from 'vet-bones/utils';
import {
  QueryBuilderCopy,
  QueryBuilderSchema,
  QueryBuilderTestIds,
} from 'vet-bones/utils/queryBuilder';

import { colors } from 'src/ui/templates/styles/colors';

export const voiceboxCopy: VoiceboxCopy = {
  voiceboxButton: {
    databaseDisabled: 'Voicebox is not enabled for this database.',
    noDatabase: 'Select a Voicebox-enabled database to chat with Voicebox.',
    text: 'Voicebox',
  },

  csvTable: {
    beforeLink: 'A preview of your results is shown above.',
    link: 'Download Results',
    afterLink: 'for the full results set.',
  },

  drawer: {
    empty: {
      title: 'Welcome to Stardog Voicebox!',
      subTitle: 'A simpler way to ask questions of your data.',
    },
    example: /* istanbul ignore next */ (idx: number) => `Spotlight ${idx + 1}`,
    placeholder: 'Write a message',
    submit: 'Send',
    title: (
      <H4>
        <span key="VOICEB">VOICEB</span>
        <VoiceboxLogo key="LOGO" size={32} />
        <span key="X">X</span>
      </H4>
    ),
  },

  errors: {
    failure: /* istanbul ignore next */ () =>
      'I was not able to process your request. Can you please rephrase or ask a different question?',
    failureWithError: /* istanbul ignore next */ () =>
      'I was not able to process your request. Can you please rephrase or ask a different question?',
    unknownError: 'An unknown error occurred.',
  },

  feedback: {
    dialog: {
      cancelButton: 'Cancel',
      negativePlaceholder:
        '[Optional] What was the issue with the response? What could be improved?',
      submitButton: 'Provide feedback',
      title: 'Provide additional feedback',
    },
  },

  message: {
    description: {
      showLess: 'show less',
      showMore: 'show more',
    },
    divider: {
      button: {
        initial: 'Settings',
        updated: 'Settings Updated',
      },
      content: {
        database: {
          label: 'Database:',
        },
        model: {
          default: 'Default',
          label: 'Model:',
        },
        namedGraphs: {
          label: 'Graph:',
        },
        reasoning: {
          label: 'Reasoning:',
          enabled: 'Yes',
          disabled: 'No',
        },
      },
    },
    menu: {
      chart: {
        chartError: 'Problem displaying chart, please try again',
        hide: 'Hide chart',
        show: 'Show chart',
      },
      copyQuery: 'Copy query',
      copyResponse: 'Copy response',
      csvTable: {
        hide: 'Hide results table',
        show: 'Show results table',
      },
      downloadResults: 'Download results',
      externalLlm: 'This answer may contain hallucinations',
      provideFeedback: 'Provide feedback',
      queryBuilder: 'Explain response',
      questionRewrittenAs: /* istanbul ignore next */ (query: string) =>
        `Voicebox interpreted your question as: ${query}`,
      regenerateResponse: 'Retry',
    },
    noCatalogProviders: {
      beforeLink:
        'You do not have any data catalogs connected. To continue, please first',
      link: 'add data catalog(s)',
      afterLink: '.',
    },
    userSenderName: 'You',
  },

  pending: {
    messages: [
      'Question received',
      'Transforming your question to a query',
      'Processing the query',
      'Validating results',
      'Summarizing results',
    ],
    timeout: 10,
  },

  queryBuilder: {
    seeQuery: 'see full query',
    score: /* istanbul ignore next */ (score: number) =>
      score === 100 ? '' : 'Some parts of the query are not shown above',
  },

  settings: {
    button: 'Voicebox Settings',
    dialog: {
      cancelButton: 'Cancel',
      database: {
        label: 'Database',
        placeholder: 'Select Database',
      },
      model: {
        label: 'Model',
        placeholder: 'Select Model',
      },
      namedGraphs: {
        dividers: {
          alias: 'Aliases',
          named: 'Named',
          virtual: 'Virtual',
        },
        label: 'Graph',
        placeholder: 'Select Graph',
        selected: /* istanbul ignore next */ (num: number) => `${num} selected`,
      },
      reasoning: {
        label: 'Reasoning',
      },
      submitButton: 'Save',
      title: 'Voicebox Settings',
    },
  },
};

const defaultStringTestIds: Omit<VoiceboxTestIds, ''> = {
  csvTable: {
    container: '',
    link: /* istanbul ignore next */ (iri, row, column) =>
      isProduction() && iri ? `${row}-${column}` : iri,
  },

  drawer: {
    body: '',
    input: '',
    loading: {
      conversationLoad: '',
      conversationRefetch: '',
    },
    submit: '',
  },

  feedback: {
    dialog: {
      body: '',
      cancelButton: '',
      input: '',
      loading: '',
      submitButton: '',
    },
  },

  messages: {
    avatar: {
      defaultUserAvatar: '',
      userInitials: '',
      voiceboxLogo: '',
    },
    chart: {
      container: '',
      error: '',
    },
    description: {
      loading: '',
      showLess: '',
      showMore: '',
      text: '',
    },
    divider: {
      button: '',
    },
    exampleCard: getTestId,
    loading: '',
    menu: {
      button: '',
      copyQuery: '',
      copyResponse: '',
      downloadCsv: '',
      externalLlmMetadata: '',
      provideFeedback: '',
      queryBuilder: '',
      regenerateResponse: '',
      rewrittenQuestion: '',
      showChart: '',
      showCsvTable: '',
    },
    noCatalogProviders: {
      link: '',
      message: '',
    },
    requestMessage: getTestId,
    responseExample: getTestId,
    responseDownloadCsv: getTestId,
    responseLink: getTestId,
    responseMessage: getTestId,
    responseQueryBuilderSeeQuery: getTestId,
  },

  settings: {
    button: '',
    dialog: {
      body: '',
      cancelButton: '',
      database: {
        selector: '',
        selectorItem: getTestId,
      },
      model: {
        selector: '',
        selectorItem: getTestId,
      },
      namedGraphs: {
        filter: '',
        selector: '',
        selectorItem: getTestId,
      },
      reasoning: '',
      submitButton: '',
    },
  },

  voiceboxButton: '',
};
processTestIds(defaultStringTestIds, '-', 'sd-voicebox');

export const voiceboxTestIds: VoiceboxTestIds = {
  ...defaultStringTestIds,
};

const voiceboxQueryBuilderCopy: QueryBuilderCopy = {
  body: {
    expression: {
      duplicate: 'Duplicate',
      remove: 'Remove',
    },
    conjunction: {
      andNot: 'AND NOT',
      or: 'OR',
    },
    class: {
      anyLabel: 'Any Class',
      placeholder: 'Select class...',
      existingInstance: 'Existing instance',
      newInstance: 'New instance',
    },
    filter: {
      loadPlaceholder: 'Search to load more...',
      placeholder: 'Filter...',
    },
    input: {
      booleanFalse: 'False',
      booleanTrue: 'True',
      datePlaceholder: 'Select date',
      dateRangePlaceholder: 'Select range',
      numberPlaceholder: 'Enter number...',
    },
    instance: {
      createNew: 'Use ',
      valueTooltip:
        'To specify an instance, select from the suggested options or provide a set of valid IRI enclosed in backticks ([`]) and comma-separated ([,])',
    },
    property: {
      attributes: 'Attributes',
      classify: 'Classify As',
      instance: 'In',
      label: 'Label',
      relationships: 'Relationships',
      placeholder: 'Select property...',
    },
    operator: {
      comparison: 'Comparison',
      placeholder: 'Set value',
    },
  },
  error: {
    // we do not show validation in voicebox
    classInstanceDefined: '',
    classModelDomainOrRange: '',
    classModelUndefined: '',
    classPreviousInstance: '',
    classSubjectConnected: '',
    classVariableInvalid: '',
    instanceValueBackticks: '',
    instanceValueCharacters: /* istanbul ignore next */ () => '',
    instanceValueSemicolon: '',
    instanceValueSplitCharacter: '',
    instanceValueStartsWith: '',
    propertyModelDomainOrRange: '',
    propertyModelUndefined: '',
  },
};

const baseVoieboxQueryBuilderTestIds: Omit<
  QueryBuilderTestIds,
  | 'classSelectorItem'
  | 'classSelectorSubItem'
  | 'instanceSelectorItem'
  | 'operatorSelectorItem'
  | 'operatorSelectorSubItem'
  | 'propertySelectorItem'
  | 'propertySelectorSubItem'
> = {
  addExpressionButton: '',
  addExpressionCascadeButton: '',
  addSubExpressionButton: '',
  classSelectorFilter: '',
  classSelectorLoading: '',
  comparisonClassSelector: '',
  comparisonPropertySelector: '',
  container: '',
  datatype: {
    booleanValue: '',
    booleanValueInput: '',
    dateRangeValue: '',
    dateValue: '',
    numericValue: '',
    numericValueInput: '',
    stringValue: '',
    stringValueInput: '',
    timeRangeValue: '',
    timeValue: '',
    value: '',
  },
  domainClassSelector: '',
  duplicateExpressionButton: '',
  error: '',
  expressionCascade: '',
  expressionContainer: '',
  filterConnector: '',
  filterContainer: '',
  fullGraphToggle: '',
  instanceSelector: '',
  instanceSelectorInput: '',
  instanceSelectorNewItem: '',
  instanceSelectorTooltipTarget: '',
  operatorSelector: '',
  propertySelector: '',
  propertySelectorFilter: '',
  propertySelectorLoading: '',
  rangeClassSelector: '',
  removeExpressionButton: '',
  removeExpressionCascadeButton: '',
  resetClearButton: '',
  subExpressionBlock: '',
  submitButton: '',
  topExpressionBlock: '',
};
processTestIds(
  baseVoieboxQueryBuilderTestIds,
  '-',
  'sd-voicebox-query-builder'
);

export const voiceboxQueryBuilderTestIds: QueryBuilderTestIds = {
  ...baseVoieboxQueryBuilderTestIds,
  classSelectorItem: /* istanbul ignore next */ (id: string, idx?: number) =>
    `sd-voicebox-query-builder-class-item-${getTestId(id, idx)}`,
  classSelectorSubItem: /* istanbul ignore next */ (
    id: string,
    subId: string,
    idx?: number,
    subIdx?: number
  ) =>
    `sd-voicebox-query-builder-class-sub-item-${getTestId(id, idx)}-${getTestId(
      subId,
      subIdx
    )}`,
  instanceSelectorItem: /* istanbul ignore next */ (id: string, idx?: number) =>
    `sd-voicebox-query-builder-instance-item-${getTestId(id, idx)}`,
  operatorSelectorItem: /* istanbul ignore next */ (id: string, idx?: number) =>
    `sd-voicebox-query-builder-operator-item-${getTestId(id, idx)}`,
  operatorSelectorSubItem: /* istanbul ignore next */ (
    id: string,
    subId: string,
    idx?: number,
    subIdx?: number
  ) =>
    `sd-voicebox-query-builder-operator-sub-item-${getTestId(
      id,
      idx
    )}-${getTestId(subId, subIdx)}`,
  propertySelectorItem: /* istanbul ignore next */ (id: string, idx?: number) =>
    `sd-voicebox-query-builder-property-item-${getTestId(id, idx)}`,
  propertySelectorSubItem: /* istanbul ignore next */ (
    id: string,
    subId: string,
    idx?: number,
    subIdx?: number
  ) =>
    `sd-voicebox-query-builder-property-sub-item-${getTestId(
      id,
      idx
    )}-${getTestId(subId, subIdx)}`,
};

export const voiceboxQueryBuilder: QueryBuilderSchema = {
  colors: {
    darkIcon: colors.darkIcon,
    anyClassColorId: colors.queryBuilderGrayId,
  },
  copy: voiceboxQueryBuilderCopy,
  testIds: voiceboxQueryBuilderTestIds,
  readOnly: true,
};

import { getTestId } from 'vet-bones/utils';

import { InstanceFlavors } from 'src/ui/features/connection';

export const ADVANCED_OPTIONS_TOGGLE = 'advanced-options-toggle';
export const DIALOG_ADD_NEW_CONNECTION = 'dialog-add-new-connection';
export const DIALOG_ADD_NEW_CLOUD = 'dialog-add-new-cloud';
export const CLOUD_PURCHASE_BUTTON = 'cloud-purchase-button';
export const CLOUD_PRIVACY_POLICY = 'sd-cloud-privacy';
export const CLOUD_TERMS_OF_SERVICE = 'sd-cloud-terms-service';
export const CONNECT_ICON_TEST_ID = 'connection-icon';
export const CONTENT_LOADING_SPINNER = 'content-loading-spinner';
export const CONNECTION_LIST_ADD_CONNECTION = 'connection-list-add-connection';
export const CONNECTION_LIST_GET_CLOUD = 'connection-list-get-cloud';

export const DASHBOARD_ADD_CONNECTION = 'dashboard-add-connection';
export const DASHBOARD_BROWSER_AUTH_FAILED_WARNING =
  'dashboard-browser-auth-failed-warning';
export const DASHBOARD_CARDS = 'dashboard-cards';
export const DASHBOARD_COMMUNITY = 'dashboard-community';
export const DASHBOARD_DEGRADED_WARNING = 'dashboard-degraded-warning';
export const DASHBOARD_DOCS = 'dashboard-docs';
export const DASHBOARD_INVITE_USERS = 'dashboard-invite-users';
export const DASHBOARD_KNOWLEDGE_KITS = 'dashboard-knowledge-kits';
export const DASHBOARD_MANAGE_DATABRICKS = 'dashboard-manage-databricks';
export const DASHBOARD_MANAGE_ENDPOINTS = 'dashboard-manage-endpoints';
export const DASHBOARD_TRAININGS = 'dashboard-trainings';
export const DASHBOARD_UNAUTHORIZED_WARNING = 'dashboard-unauthorized-warning';

export const DIALOG_CLOSE_ICON_TEST_ID = 'dialog-close-icon';
export const DIALOG_ICON_TEST_ID = 'dialog-icon';

export const DIAGNOSTIC_VOICEBOX_DATABASE_SELECTOR =
  'diagnostic-voicebox-database-selector';
export const DIAGNOSTIC_VOICEBOX_DATABASE_SELECTOR_ITEM = (
  id: string,
  idx?: number
) => `diagnostic-voicebox-database-selector-item-${getTestId(id, idx)}`;
export const DIAGNOSTIC_VOICEBOX_MODEL_SELECTOR =
  'diagnostic-voicebox-model-selector';
export const DIAGNOSTIC_VOICEBOX_MODEL_SELECTOR_ITEM = (
  id: string,
  idx?: number
) => `diagnostic-voicebox-model-selector-item-${getTestId(id, idx)}`;
export const DIAGNOSTIC_VOICEBOX_GENERATE_REPORT_BUTTON =
  'diagnostic-voicebox-generate-report-button';
export const DIAGNOSTIC_VOICEBOX_RESULTS_SPINNER =
  'diagnostic-voicebox-results-spinner';

export const LANDING_PAGE_ADD_CONNECTION = 'landing-page-add-connection';
export const LANDING_PAGE_CONNECTION_SELECTOR =
  'landing-page-connection-selector';
export const LANDING_PAGE_GET_STARTED = 'landing-page-get-started';
export const LANDING_PAGE_MANAGE_ENDPOINTS = 'landing-page-manage-endpoints';
export const LANDING_PAGE_VOICEBOX = 'landing-page-voicebox';

export const PRODUCT_DESC_TEST_ID = 'product-description';
export const DATABRICKS_CREATE_CONNECTION = 'databricks-create-connection';
export const DATABRICKS_CONNECTION_NAME = 'databricks_conn_name';
export const DATABRICKS_CONNECTION_SELECTOR = 'databricks_conn_selector';
export const FOOTER_LINKS_CONTAINER = 'footer-links-container';

export const CREATE_PROFILE_DIALOG_INDUSTRY_SELECTOR =
  'create-dialog-industry-selector';
export const CREATE_PROFILE_BEST_DESCRIBES_COMPANY_SELECTOR =
  'create-dialog-best-describes-company-selector';
export const CREATE_PROFILE_DIALOG_BEST_DESCRIBES_ROLE_SELECTOR =
  'create-dialog-best-describes-role-selector';
export const CREATE_PROFILE_TERMS_OF_USE_CHECKBOX =
  'create-dialog-terms-of-use-checkbox';
export const CREATE_PROFILE_PRIVACY_CHECKBOX = 'create-dialog-privacy-checkbox';

export const NAV_CONTAINER = 'nav-container';
export const NAV_HELP_BUTTON = 'help-button';
export const NAV_HELP_MENU = 'nav-help-menu';
export const NAV_EMPLOYEE_HOME_BUTTON = 'nav-employee-home-button';
export const NAV_EXPAND_LEFT_NAVBAR_BUTTON = 'nav-expand-left-navbar-button';

export const HELP_EXAMPLE_CLI = 'help-example-cli';
export const HELP_EXAMPLE_JS = 'help-example-js';
export const HELP_EXAMPLE_JAVA = 'help-example-java';
export const HELP_EXAMPLE_PY = 'help-example-py';

export const GET_STARTED_CTA = (flavor: InstanceFlavors) =>
  `get-started-cta-${flavor}`;
export const GET_STARTED_HEADER = 'get-started-header';
export const GET_STARTED_OVERVIEW = 'get-started-overview';

export const BUTTON_EDIT = 'button-edit';
export const BUTTON_MP = 'button-mp';
export const BUTTON_DEV = 'button-dev';
export const BUTTON_HOME = 'button-home';

export const GS_DB = 'gs-db';
export const GS_OPEN = 'gs-open';
export const GS_GO = 'gs-go';

export const LEFT_NAVBAR_SELECTOR = 'left-navbar-selector';
export const LEFT_NAVBAR_SELECTOR_ITEM = (id: string) =>
  `left-navbar-selector-item-${id}`;

export const MP_MENU_SEARCH = 'MP_MENU_SEARCH';
export const MP_MENU_SEARCH_BTN = 'MP_MENU_SEARCH_BTN';
export const MP_MENU_FILTER = 'MP_MENU_FILTER';

export const KIT_CONNECTION_SELECTOR = 'kit-connection-selector';
export const KIT_CONNECTION_SELECTOR_OPTION = 'kit-connection-selector-option';
export const KIT_DB_SELECTOR_BUTTON = 'kit-db-selector-button';
export const KIT_DB_SELECTOR_MENU_ITEM = 'kit-db-selector-menu-item';
export const KIT_DB_SELECTOR_CREATE_NEW_MENU_ITEM =
  'kit-db-selector-create-menu-item';
export const KIT_DB_SELECTOR_CREATE_NEW_INVALID_MENU_ITEM =
  'kit-db-selector-create-invalid-menu-item';
export const KIT_ACTION_BUTTON = 'kit-action-button';
export const KIT_EXPLORER_BUTTON = 'kit-explorer-button';
export const KIT_OVERVIEW_PANEL = (id: string) => `overview-panel-${id}`;
export const KIT_OVERVIEW_CLOSE_SIDEBAR_BUTTON =
  'overview-close-sidebar-button';
export const KIT_VOICEBOX_ICON = 'kit-voicebox-icon';

export const MANAGE_API_TOKENS_CONTAINER = 'manage-api-tokens-container';
export const MANAGE_API_TOKENS_APP_CREATE = 'manage-api-tokens-app-create';
export const MANAGE_API_TOKENS_APP_TABLE = (id: string, idx?: number) =>
  `manage-api-tokens-app-table-${getTestId(id, idx)}`;
export const MANAGE_API_TOKENS_USER_CREATE = 'manage-api-tokens-user-create';
export const MANAGE_API_TOKENS_USER_TABLE = 'manage-api-tokens-user-table';

export const MANAGE_API_TOKEN_CREATE_DIALOG = 'manage-api-token-create-dialog';
export const MANAGE_API_TOKEN_CREATE_NAME_INPUT =
  'manage-api-token-create-name-input';
export const MANAGE_API_TOKEN_CREATE_EXPIRES_INPUT =
  'manage-api-token-create-expires-input';
export const MANAGE_API_TOKEN_CREATE_SECRET = 'manage-api-token-create-secret';

export const MANAGE_API_TOKEN_MENU = 'manage-api-token-menu';
export const MANAGE_API_TOKEN_DELETE = 'manage-api-token-delete';
export const MANAGE_API_TOKEN_DELETE_CANCEL = 'manage-api-token-delete-cancel';
export const MANAGE_API_TOKEN_DELETE_SUBMIT = 'manage-api-token-delete-submit';
export const MANAGE_API_TOKEN_ROW = (id: string, idx?: number) =>
  `manage-api-token-row-${getTestId(id, idx)}`;

export const MANAGE_VOICEBOX_APP_CREATE = 'manage-voicebox-app-create';
export const MANAGE_VOICEBOX_APP_MENU = 'manage-voicebox-app-menu';
export const MANAGE_VOICEBOX_APP_MENU_DELETE =
  'manage-voicebox-app-menu-delete';
export const MANAGE_VOICEBOX_APP_MENU_UPDATE =
  'manage-voicebox-app-menu-update';

export const MANAGE_VOICEBOX_APP_DELETE_CANCEL =
  'manage-voicebox-app-delete-cancel';
export const MANAGE_VOICEBOX_APP_DELETE_SUBMIT =
  'manage-voicebox-app-delete-submit';

export const MANAGE_VOICEBOX_APP_CREATE_DIALOG =
  'manage-voicebox-app-create-dialog';
export const MANAGE_VOICEBOX_APP_CREATE_CONNECTIONS =
  'manage-voicebox-app-create-connections';
export const MANAGE_VOICEBOX_APP_CREATE_CONNECTIONS_EMPTY =
  'manage-voicebox-app-create-connections-empty';
export const MANAGE_VOICEBOX_APP_CREATE_CONNECTION = (
  id: string,
  idx?: number
) => `manage-voicebox-app-create-connection-${getTestId(id, idx)}`;
export const MANAGE_VOICEBOX_APP_CREATE_DATABASE_ITEM = (
  id: string,
  idx?: number
) => `manage-voicebox-app-create-database-item-${getTestId(id, idx)}`;
export const MANAGE_VOICEBOX_APP_CREATE_DATABASE_SELECTOR =
  'manage-voicebox-app-create-database-selector';
export const MANAGE_VOICEBOX_APP_CREATE_GRAPH_ITEM = (
  id: string,
  idx?: number
) => `manage-voicebox-app-create-graph-item-${getTestId(id, idx)}`;
export const MANAGE_VOICEBOX_APP_CREATE_GRAPH_SELECTOR =
  'manage-voicebox-app-create-graph-selector';
export const MANAGE_VOICEBOX_APP_CREATE_MODEL_ITEM = (
  id: string,
  idx?: number
) => `manage-voicebox-app-create-model-item-${getTestId(id, idx)}`;
export const MANAGE_VOICEBOX_APP_CREATE_MODEL_SELECTOR =
  'manage-voicebox-app-create-model-selector';
export const MANAGE_VOICEBOX_APP_CREATE_NAME_INPUT =
  'manage-voicebox-app-create-name-input';
export const MANAGE_VOICEBOX_APP_CREATE_REASONING_SWITCH =
  'manage-voicebox-app-create-reasoning-switch';

export const MANAGE_VOICEBOX_APP_UPDATE_DIALOG =
  'manage-voicebox-app-update-dialog';
export const MANAGE_VOICEBOX_APP_UPDATE_NAME_INPUT =
  'manage-voicebox-app-update-name-input';

export const VOICEBOX_PAGE_DRAWER_BODY_CONTAINER =
  'voicebox-page-drawer-body-container';
export const VOICEBOX_PAGE_SIDEBAR_COLLAPSE = 'voicebox-page-sidebar-collapse';
export const VOICEBOX_PAGE_SIDEBAR_ITEM = (id: string, idx?: number) =>
  `voicebox-page-sidebar-item-${getTestId(id, idx)}`;
export const VOICEBOX_PAGE_SIDEBAR_NEW = 'voicebox-page-sidebar-new';
export const VOICEBOX_PAGE_SIDEBAR_SEARCH_INPUT =
  'voicebox-page-sidebar-search-input';
export const VOICEBOX_PAGE_SIDEBAR_SEARCH_BUTTON =
  'voicebox-page-sidebar-search-button';
export const VOICEBOX_PAGE_SIDEBAR_SEARCH_CLOSE_BUTTON =
  'voicebox-page-sidebar-search-close-button';
export const VOICEBOX_PAGE_SIDEBAR_ITEM_MENU =
  'voicebox-page-sidebar-item-menu';
export const VOICEBOX_PAGE_SIDEBAR_ITEM_RENAME_MENU_ITEM =
  'voicebox-page-sidebar-item-rename-menu-item';
export const VOICEBOX_PAGE_SIDEBAR_ITEM_RENAME_INPUT =
  'voicebox-page-sidebar-item-rename-input';
export const VOICEBOX_PAGE_SIDEBAR_ITEM_DELETE_MENU_ITEM =
  'voicebox-page-sidebar-item-delete-menu-item';
export const VOICEBOX_PAGE_SIDEBAR_ITEM_DELETE_CONFIRMATION =
  'voicebox-page-sidebar-item-delete-confirmation';
export const VOICEBOX_PAGE_SIDEBAR_ITEM_DELETE_CANCEL =
  'voicebox-page-sidebar-item-delete-cancel';

export const INTERNAL_HOME_ACTION_SEARCH = 'internal-home-action-search';
export const INTERNAL_USER_SEARCH = 'internal-user-search';
export const INTERNAL_USER_SEARCH_DOWNLOAD = 'internal-user-search-download';
export const INTERNAL_USER_SEARCH_FILTER = (id: string) =>
  `internal-user-search-filter-${id}`;
export const INTERNAL_USER_DASHBOARD_CLOUDS = 'internal-user-dashboard-clouds';
export const INTERNAL_USER_DASHBOARD_FEATURE_TOGGLE = (id: string) =>
  `internal-user-dashboard-feature-toggle-${id}`;
export const INTERNAL_USER_DASHBOARD_BACK_TO_SEARCH =
  'internal-user-dashboard-back-to-search';

export const SHARE_ENDPOINT_ACCESS_LEVEL_BUTTON =
  'share-endpoint-access-level-button';
export const SHARE_ENDPOINT_ACCESS_LEVEL_ITEM = (id: string) =>
  `share-endpoint-access-level-item-${id}`;

export const UPGRADE_PLAN_DIALOG_CLOSE_BUTTON =
  'upgrade-plan-dialog-close-button';

export const DELETE_ACCOUNT_DIALOG_DELETE_ACCOUNT_BUTTON =
  'delete-account-dialog-delete-account-button';
export const DELETE_ACCOUNT_DIALOG_CONFIRMATION_INPUT =
  'delete-account-dialog-delete-account-input';

export const CANCEL_CLOUD_DIALOG_CANCEL_CLOUD_BUTTON =
  'cancel-cloud-dialog-cancel-cloud-button';
export const CANCEL_CLOUD_DIALOG_CONFIRMATION_INPUT =
  'cancel-cloud-dialog-cancel-cloud-input';

export const INVITATION_ENDPOINT_NAME_INPUT = 'invitation-endpoint-name-input';

export const VERIFY_EMAIL_DIALOG_RESEND_BUTTON =
  'verify-email-dialog-resend-button';
export const VERIFY_EMAIL_DIALOG_CLOSE_BUTTON =
  'verify-email-dialog-close-button';

export const RADIO_SELECT_INPUT = 'radio-select-input';
export const CONNECTION_DIALOG_OWNERS_CAN_ONLY_DELETE_TOOLTIP =
  'connection-dialog-owners-can-only-delete-tooltip';

export const LAUNCHPAD_ADD_BASIC_CONNECTION_BUTTON =
  'launchpad-add-basic-connection-button';
export const LAUNCHPAD_DELETE_CONNECTION_BUTTON =
  'launchpad-delete-connection-button';
export const LAUNCHPAD_ACCOUNT_MENU_BUTTON = 'launchpad-account-menu-button';

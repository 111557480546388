import {
  Button,
  Callout,
  Collapse,
  H3,
  Icon,
  Intent,
  Tag,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';

import { CopyToClipboardButton } from 'src/ui/components/CopyToClipboardButton';
import { LAUNCHPAD_DELETE_CONNECTION_BUTTON } from 'src/ui/constants/testIds';
import { Connection as GraphConnection } from 'src/ui/graph/types';
import { components } from 'src/ui/templates/copy';

type ConnectionDetailsProps = {
  connection: GraphConnection;
  onReauthenticateSSOConnection: () => void;
  onReauthenticateUserPasswordConnection: () => void;
  onLogoutSSOConnection: () => void;
  onEditConnection: () => void;
  onDeleteConnection: () => void;
  onDiagnostics: () => void;
  isBrowserAuthFailure: boolean;
  isUnauthorized: boolean;
  isAlive: boolean;
  alivenessCheckErrorMessage: string;
};

/* istanbul ignore next */
export const ConnectionDetails: React.FC<ConnectionDetailsProps> = ({
  connection,
  onReauthenticateSSOConnection,
  onReauthenticateUserPasswordConnection,
  onEditConnection,
  onDeleteConnection,
  onLogoutSSOConnection,
  onDiagnostics,
  isUnauthorized,
  isBrowserAuthFailure,
  isAlive,
  alivenessCheckErrorMessage,
}) => {
  const [ssoControlsOpen, setSSOControlsOpen] = React.useState<boolean>(false);

  const toggleSSOControls = () => setSSOControlsOpen(!ssoControlsOpen);
  const hasError = isUnauthorized || isBrowserAuthFailure || !isAlive;
  const { connectionDetails } = components.launchpad;

  const renderReauthorizationButton = (isSSO: boolean) => (
    <Button
      icon={IconNames.Refresh}
      intent={Intent.WARNING}
      minimal
      outlined
      text={components.launchpad.connectionDetails.buttons.reauthenticate}
      onClick={
        isSSO
          ? onReauthenticateSSOConnection
          : onReauthenticateUserPasswordConnection
      }
    />
  );

  const renderAuthorizationError = () => {
    const renderCallout = (title: string, content: JSX.Element | string) => (
      <Callout
        className="launchpad-dashboard__connection-details__error-callout"
        title={title}
        intent={Intent.WARNING}
      >
        <div>{content}</div>
      </Callout>
    );

    const handleBrowserAuthFailure = () =>
      renderCallout(
        connectionDetails.errorCallouts.browserAuthFailure.title,
        connectionDetails.errorCallouts.browserAuthFailure.body
      );

    const handleSSOFailure = () => {
      if (!connection.token) {
        return renderCallout(
          connectionDetails.errorCallouts.ssoAuthFailure.noToken.title,
          connectionDetails.errorCallouts.ssoAuthFailure.noToken.body(
            renderReauthorizationButton(true)
          )
        );
      }

      try {
        const tokenData = JSON.parse(atob(connection.token.split('.')[1]));
        const roleClaims = tokenData.roles || [];
        return renderCallout(
          connectionDetails.errorCallouts.ssoAuthFailure.existingToken.title,
          connectionDetails.errorCallouts.ssoAuthFailure.existingToken.body(
            roleClaims,
            renderReauthorizationButton(true)
          )
        );
      } catch (error) {
        return renderCallout(
          connectionDetails.errorCallouts.ssoAuthFailure.existingTokenFallback
            .title,
          connectionDetails.errorCallouts.ssoAuthFailure.existingTokenFallback.body(
            renderReauthorizationButton(true)
          )
        );
      }
    };

    const handleGenericAuthFailure = () => {
      return renderCallout(
        connectionDetails.errorCallouts.basicAuthFailure.title,
        connectionDetails.errorCallouts.basicAuthFailure.body(
          renderReauthorizationButton(false)
        )
      );
    };

    if (connection.useBrowserAuth && isBrowserAuthFailure) {
      return handleBrowserAuthFailure();
    }

    if (connection.useConnectionSSO && isUnauthorized) {
      return handleSSOFailure();
    }

    if (
      !connection.useBrowserAuth &&
      !connection.useConnectionSSO &&
      isUnauthorized
    ) {
      return handleGenericAuthFailure();
    }

    return null;
  };

  const renderAlivenessCheck = () => (
    <div className="launchpad-dashboard__connection-details__aliveness-check">
      <div className="launchpad-dashboard__connection-details__row">
        <strong>Aliveness Check:</strong>
        {isAlive ? (
          <Icon intent={Intent.SUCCESS} icon={IconNames.TickCircle} />
        ) : (
          <Icon intent={Intent.DANGER} icon={IconNames.Error} />
        )}
      </div>
      <em>{alivenessCheckErrorMessage}</em>
    </div>
  );

  const renderSSOControls = () => (
    <div>
      <Button
        minimal
        small
        icon={
          ssoControlsOpen ? IconNames.CHEVRON_DOWN : IconNames.CHEVRON_RIGHT
        }
        onClick={toggleSSOControls}
      >
        {connectionDetails.collapsibleSections.ssoControls}
      </Button>

      <Collapse isOpen={ssoControlsOpen}>
        <div className="launchpad-dashboard__connection-details__buttons">
          <Button
            icon={IconNames.Refresh}
            intent={Intent.PRIMARY}
            minimal
            outlined
            text={connectionDetails.buttons.reauthenticate}
            onClick={onReauthenticateSSOConnection}
          />
          <Button
            icon={IconNames.LogOut}
            intent={Intent.DANGER}
            minimal
            outlined
            text={connectionDetails.buttons.logout}
            onClick={onLogoutSSOConnection}
            disabled={!connection?.token}
          />
        </div>
      </Collapse>
    </div>
  );

  return (
    <div className="launchpad-dashboard__connection-details">
      <div className="launchpad-dashboard__connection-details__header">
        <H3>{connection.name}</H3>
        {connection.useSSO && (
          <Tag intent={Intent.PRIMARY} minimal large>
            {connectionDetails.tags.sso}
          </Tag>
        )}
      </div>
      <hr className="launchpad-dashboard__connection-details__divider" />
      <div className="launchpad-dashboard__connection-details__content">
        <div>
          <strong>{connectionDetails.info.username}:</strong>{' '}
          {connection.username}
        </div>
        <div className="launchpad-dashboard__connection-details__row">
          <div className="launchpad-dashboard__connection-details__row">
            <div>
              <strong>{connectionDetails.info.endpoint}:</strong>{' '}
              {connection.endpoint}
            </div>
            {hasError ? (
              <Icon intent={Intent.DANGER} icon={IconNames.Error} />
            ) : (
              <Icon intent={Intent.SUCCESS} icon={IconNames.TickCircle} />
            )}
          </div>
          <div className="launchpad-dashboard__connection-details__divider" />
          <CopyToClipboardButton textToCopy={connection.endpoint || ''} />
          {hasError && (
            <Button
              icon={IconNames.Diagnosis}
              intent={Intent.WARNING}
              minimal
              outlined
              onClick={onDiagnostics}
            >
              {connectionDetails.buttons.diagnostics}
            </Button>
          )}
        </div>
        {renderAlivenessCheck()}
      </div>
      {renderAuthorizationError()}
      <div className="launchpad-dashboard__connection-details__buttons">
        <Button
          icon={IconNames.Edit}
          onClick={onEditConnection}
          intent={Intent.PRIMARY}
          outlined
          minimal
        >
          {connectionDetails.buttons.edit}
        </Button>
        <Button
          data-testid={LAUNCHPAD_DELETE_CONNECTION_BUTTON}
          icon={IconNames.Trash}
          onClick={onDeleteConnection}
          intent={Intent.DANGER}
          outlined
          minimal
        >
          {connectionDetails.buttons.delete}
        </Button>
      </div>
      {connection.useSSO && renderSSOControls()}
    </div>
  );
};

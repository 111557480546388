import { Button, H1, Intent, MenuItem, NonIdealState } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ItemPredicate, ItemRenderer, Select2 } from '@blueprintjs/select';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'src/ui/app/hooks';
import { Loading } from 'src/ui/components/Loading';
import { LAUNCHPAD_ADD_BASIC_CONNECTION_BUTTON } from 'src/ui/constants/testIds';
import { ConnectionDetails } from 'src/ui/containers/launchpad/dashboard/ConnectionDetails';
import { StardogApps } from 'src/ui/containers/launchpad/dashboard/StardogApps';
import {
  Connection,
  connectionInvalid,
  deleteConnection,
  editConnection,
  setConnectionBrowserAuthFailed,
  setConnectionUnauthorized,
} from 'src/ui/features/connection';
import { queueMessage } from 'src/ui/features/notifications';
import {
  showAddNewDialog,
  showAddSSOConnection,
  showConnectionDialog,
} from 'src/ui/features/ui';
import { graphQLClient } from 'src/ui/graph/graphQLClient';
import {
  Connection as GraphConnection,
  useLogoutSsoConnectionMutation,
  useReauthenticateSsoConnectionMutation,
  useSavedConnectionsQuery,
} from 'src/ui/graph/types';
import { useVerifyConnection } from 'src/ui/hooks/connection';
import { useHealthCheck } from 'src/ui/hooks/diagnostic';
import { useOnError } from 'src/ui/hooks/graph';
import {
  isBrowserAuthFailureSelector,
  isUnauthorizedSelector,
} from 'src/ui/selectors/connection';
import { components } from 'src/ui/templates/copy';
import {
  doDesignerRedirect,
  doExplorerRedirect,
  doStudioRedirect,
  redirectToUrl,
} from 'src/ui/utils/window';

type TParams = { id?: string };

/* istanbul ignore next */
export const Dashboard: React.VFC = () => {
  const { data, isLoading, refetch } = useSavedConnectionsQuery(graphQLClient);
  const { mutate: verifyConnection } = useVerifyConnection();
  const { mutate: checkAliveness } = useHealthCheck();
  const [isValidated, setIsValidated] = React.useState<boolean>(false);
  const [isAlive, setIsAlive] = React.useState<boolean>(false);
  const [
    healthCheckErrorMessage,
    setHealthCheckErrorMessage,
  ] = React.useState<string>('');
  const dispatch = useAppDispatch();
  const isBrowserAuthFailure = useAppSelector(isBrowserAuthFailureSelector);
  const isUnauthorized = useAppSelector(isUnauthorizedSelector);
  const history = useHistory();
  const params = useParams<TParams>();
  const [
    currentConnection,
    setCurrentConnection,
  ] = React.useState<GraphConnection | null>(null);
  const onError = useOnError();

  const {
    mutate: reauthenticateSSOConnectionMutation,
  } = useReauthenticateSsoConnectionMutation(graphQLClient, {
    onError,
    onSuccess: (data) => {
      if (data?.reauthenticateSSOConnection) {
        redirectToUrl(data?.reauthenticateSSOConnection?.redirect_url);
      }
    },
  });

  const {
    mutate: logoutSSOConnectionMutation,
  } = useLogoutSsoConnectionMutation(graphQLClient, {
    onError,
    onSuccess: (data) => {
      if (data?.logoutSSOConnection?.success) {
        dispatch(
          queueMessage({
            intent: Intent.SUCCESS,
            message: `Logged out of ${currentConnection?.name}`,
          })
        );
      }
      setIsValidated(false);
      const currentIndex = currentConnection?.index;
      refetch();
      history.push(`/u/${currentIndex}`);
    },
  });

  React.useEffect(() => {
    if (data?.listConnections) {
      if (params.id) {
        const connection =
          data.listConnections.find((c) => c?.index === Number(params.id)) ||
          null;
        setCurrentConnection(connection);
      } else if (data.listConnections.length > 0 && !currentConnection) {
        const firstConnection = data.listConnections[0];
        setCurrentConnection(firstConnection);
        history.push(`/u/${firstConnection?.index}`);
      } else if (data.listConnections.length === 0) {
        history.push('/');
      }
    }
  }, [data, params.id, currentConnection, history]);

  React.useEffect(() => {
    if (!currentConnection || isValidated || isUnauthorized) {
      return;
    }

    verifyConnection(currentConnection as Connection, {
      onSuccess: () => setIsValidated(true),
      onError: (_error) => {
        if (currentConnection.useBrowserAuth) {
          // If useBrowserAuth was enabled and validate connection failed,
          // show banner warning for this
          dispatch(setConnectionBrowserAuthFailed(true));
        } else if (currentConnection.useConnectionSSO) {
          // If they are using sso, then there they are most likely not
          // authorized or some other error with communication
          dispatch(setConnectionUnauthorized(true));
        } else {
          // If they are using Stardog token it has expired and they need
          // re-enter their password to get a new token.
          dispatch(setConnectionUnauthorized(true));
          dispatch(connectionInvalid(currentConnection?.index));
          dispatch(showConnectionDialog());
        }
      },
    });
  }, [
    isValidated,
    isUnauthorized,
    currentConnection,
    verifyConnection,
    dispatch,
  ]);

  React.useEffect(() => {
    if (!currentConnection) {
      return;
    }

    checkAliveness(currentConnection.endpoint, {
      onError: (error) => {
        setIsAlive(false);
        setHealthCheckErrorMessage(error.message);
      },
      onSuccess: () => setIsAlive(true),
    });
  }, [currentConnection, checkAliveness]);

  React.useEffect(() => {
    setIsValidated(false);
    dispatch(setConnectionBrowserAuthFailed(false));
    dispatch(setConnectionUnauthorized(false));
    setIsAlive(false);
    setHealthCheckErrorMessage('');
  }, [currentConnection, dispatch]);

  const handleAddSSOConnection = () => dispatch(showAddSSOConnection());

  const handleReauthenticateSSOConnection = () => {
    if (currentConnection) {
      reauthenticateSSOConnectionMutation({
        input: { connection_id: currentConnection?.id },
      });
    }
  };

  const handleLogoutSSOConnection = () => {
    if (currentConnection) {
      logoutSSOConnectionMutation({
        input: { connection_id: currentConnection?.id },
      });
    }
  };

  const handleReauthenticateUserPasswordConnection = () => {
    if (currentConnection) {
      dispatch(connectionInvalid(currentConnection?.index));
      dispatch(showConnectionDialog());
    }
  };

  const handleAddConnection = () => dispatch(showAddNewDialog());

  const handleEditConnection = () => {
    if (
      currentConnection?.index !== undefined &&
      currentConnection?.index !== null
    ) {
      dispatch(editConnection(currentConnection?.index));
    }
  };

  const handleDeleteConnection = () => {
    if (
      currentConnection?.index !== undefined &&
      currentConnection?.index !== null
    ) {
      dispatch(deleteConnection(currentConnection?.index));
    }
  };

  const handleConnectionChange = (newConnection: GraphConnection) => {
    setCurrentConnection(newConnection);
    history.push(`/u/${newConnection.index}`);
  };

  const connections =
    data?.listConnections?.filter((c): c is GraphConnection => c !== null) ??
    [];

  const renderConnection: ItemRenderer<GraphConnection> = (
    connection: GraphConnection,
    { handleClick, handleFocus, modifiers }
  ) => (
    <MenuItem
      key={connection.id}
      text={connection.name}
      onClick={handleClick}
      onFocus={handleFocus}
      active={modifiers.active}
      roleStructure="listoption"
      disabled={modifiers.disabled}
    />
  );

  const filterConnection: ItemPredicate<GraphConnection> = (
    query,
    connection,
    _index,
    exactMatch
  ) => {
    const normalizedName = connection.name.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
      return normalizedName === normalizedQuery;
    }
    return `${normalizedName}`.indexOf(normalizedQuery) >= 0;
  };

  const connectionSelector = () => {
    return (
      <Select2<GraphConnection>
        items={connections}
        itemRenderer={renderConnection}
        itemPredicate={filterConnection}
        onItemSelect={handleConnectionChange}
        filterable={true}
      >
        <Button
          minimal
          outlined
          text={
            currentConnection?.name || components.launchpad.connections.selector
          }
          rightIcon="double-caret-vertical"
        />
      </Select2>
    );
  };

  const addConnectionButtons = () => {
    return (
      <div
        style={{
          display: 'flex',
          gap: '1rem',
        }}
      >
        <Button
          data-testid={LAUNCHPAD_ADD_BASIC_CONNECTION_BUTTON}
          icon={IconNames.Plus}
          onClick={handleAddConnection}
          intent="primary"
          outlined
          minimal
        >
          {components.launchpad.connections.addBasic}
        </Button>
        <Button
          icon={IconNames.LOG_IN}
          onClick={handleAddSSOConnection}
          intent="primary"
          outlined
          minimal
        >
          {components.launchpad.connections.addSso}
        </Button>
      </div>
    );
  };

  return isLoading ? (
    <Loading />
  ) : (
    <main className="launchpad-dashboard">
      <section className="launchpad-dashboard__connection">
        <header>
          <H1>{components.launchpad.dashboard.title}</H1>
        </header>
        <div className="launchpad-dashboard__connection-selector">
          {data?.listConnections && data.listConnections.length > 0 ? (
            <>
              {connectionSelector()}
              {addConnectionButtons()}
            </>
          ) : (
            <NonIdealState
              icon={IconNames.DataConnection}
              title={components.launchpad.emptyState.title}
              description={components.launchpad.emptyState.description}
              action={addConnectionButtons()}
            />
          )}
        </div>
        {currentConnection && (
          <ConnectionDetails
            isAlive={isAlive}
            alivenessCheckErrorMessage={healthCheckErrorMessage}
            connection={currentConnection}
            onReauthenticateSSOConnection={handleReauthenticateSSOConnection}
            onReauthenticateUserPasswordConnection={
              handleReauthenticateUserPasswordConnection
            }
            onLogoutSSOConnection={handleLogoutSSOConnection}
            onEditConnection={handleEditConnection}
            onDeleteConnection={handleDeleteConnection}
            onDiagnostics={() => {
              redirectToUrl(`/u/${currentConnection.index}/diagnostic`);
            }}
            isUnauthorized={isUnauthorized}
            isBrowserAuthFailure={isBrowserAuthFailure}
          />
        )}
      </section>

      {currentConnection && (
        <StardogApps
          currentIndex={currentConnection?.index || null}
          goToExplorer={doExplorerRedirect}
          goToStudio={doStudioRedirect}
          goToDesigner={doDesignerRedirect}
        />
      )}
    </main>
  );
};
